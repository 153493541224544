import React from "react";
import "./CourseList.css";
import { Swiper, SwiperSlide } from "swiper/react";
import OriginalCoursesCard from "../OriginalCourses/OriginalCoursesCard/OriginalCoursesCard";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { Link } from "react-router-dom";
import { certifier } from "../../mocks/certifier";

function undoTranslateTypeCourse(typeCourse) {
  const translatedTypeCourse = {
    "Pós-Graduação": "posGraduacao",
    "Cursos Livres": "cursosLivres"
  };
  return translatedTypeCourse[typeCourse] || typeCourse;
}

function CourseList({ area, isAvailableCourses, seeAllContent, disableSeeAllButton }) {
  return (
    <section className="swiper-cards-progress w-full flex flex-col gap-1">
      <div className="flex flex-col gap-1">
        <div className="flex w-full justify-between items-center">
          <div className="flex w-2/3 flex-col gap-0.5 max-lg:w-full">
            <span className="font-bold text-[1.5rem] text-left leading-tight max-lg:text-[1.25rem]">
              {area.areaName}
            </span>
          </div>
          {!disableSeeAllButton && area && area.courses.length > 4 && (
            <Link
              className="flex w-fit min-w-[136.5px] justify-end max-lg:hidden"
              to={
                area.specifiedRedirect ??
                `/searchArea?area=${area.areaAlias}${
                  seeAllContent ? `&typeCourse=${seeAllContent}` : ""
                }`
              }
              reloadDocument>
              <ButtonPrimary
                textButton="ver todos"
                desktopWidth="100%"
                mobileWidth="100%"
                outlineType
                outlineColor={certifier.colors.text.textPrimary}
                optionalTextColor={certifier.colors.text.textPrimary}
                optionalClass="hoverWhiteBg"
              />
            </Link>
          )}
        </div>

        <div className="w-full">
          <Swiper
            className="w-full"
            style={{
              width: "100%",
              "--swiper-navigation-color": "#fff",
              "--swiper-navigation-size": "30px",
              "--swiper-navigation-top-offset": "0%",
              "--swiper-navigation-sides-offset": "0px"
            }}
            centeredSlides={true}
            centeredSlidesBounds={true}
            slidesPerView={1.5}
            slidesPerGroup={1}
            navigation={true}
            spaceBetween={15}
            speed={450}
            breakpoints={{
              1025: {
                centeredSlides: false,
                centeredSlidesBounds: false,
                slidesPerView: 4,
                slidesPerGroup: 4,
                style: { marginLeft: "0%", width: "100%" }
              }
            }}>
            {area &&
              area.courses &&
              area.courses.map((course, index) => (
                <SwiperSlide key={index} className="w-full">
                  <OriginalCoursesCard
                    courseId={course.courseId}
                    key={index}
                    image={course.image}
                    typeCourse={course.typeCourse}
                    name={course.name}
                    teacher={course.teacher}
                    courseStars={
                      course.stars
                      ? course.stars.averageStars
                      : undefined
                    } //////////
                    redirectTo={
                      course.unlockCourse
                        ? `/course/${course.alias}`
                        // Alteração pertecente à atualização de integração ao Joel Jota
                        : (isAvailableCourses && course.courseId !== "67acee9b22a8d5001205a121")
                        ? `/rescueCourse/${undoTranslateTypeCourse(
                            course.typeCourse
                          )}/${course.alias}`
                        : `/purchaseCourse/${undoTranslateTypeCourse(
                            course.typeCourse
                          )}/${course.alias}`
                    }
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default CourseList;
