import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

import { register } from "swiper/element/bundle";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

register();

Sentry.init({
  dsn: "https://edb998eb01a834efde0627bd5719f27b@o4507074725871616.ingest.us.sentry.io/4507074768994304",
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate:
    process.env.REACT_APP_ENVIRONMENT === "production" ||
    process.env.REACT_APP_ENVIRONMENT === "sandbox"
      ? 1.0
      : 0.1,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/libanoeducacional\.com.br\//,
  ],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate:
    process.env.REACT_APP_ENVIRONMENT === "production" ||
    process.env.REACT_APP_ENVIRONMENT === "sandbox"
      ? 1.0
      : 0.1,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
