import moment from "moment";
import getStudentInfos from "../../getStudentInfos/getStudentInfos";
import { CryptoService } from "../../cryptoService/crypto.service";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Toastify } from "../../../components/Toastify/Toastify";
import { certifier } from "../../../mocks/certifier";

export default async function SubscribeGraduation25() {
  try {
  const student = await getStudentInfos();
   const sessionId = student.sessionId;
   const post_data = await CryptoService(
     {
       sessionId,certifierName: certifier.generalSettings.name,
       exp: moment().add(2, "hours").unix(),
     },
     "encrypt-jwt"
   );
   const config = {
     headers: {
       auth: `${post_data}`,
     },
   };
   const response = await axios.get(
     `${process.env.REACT_APP_API_ADDRESS}/insc`,
     config
   );
   if (response.status === 201) {
    Toastify("success", "Você entrou na lista de espera!");
    return true
  } else {
     Toastify("error", "Ooops! Algo aconteceu. Tente novamente mais tarde!");
     return false
    }
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return false
    }
    Toastify("error", "Ooops! Algo saiu mal. Tente novamente mais tarde!");
    return false
  }
};