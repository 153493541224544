import {
  HiBars3CenterLeft,
  HiMiniCheck,
  HiOutlineArrowPathRoundedSquare,
  HiOutlineSquare2Stack
} from "react-icons/hi2";
import { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import {
  requestRegenerateLiaMessage,
  requestLiaChats,
  requestLiaRunStatus,
  requestLiaStatus,
  requestNewLiaMessage,
  requestSpecifiedLiaChat
} from "../../services/aiService/ai.service";
import { motion, AnimatePresence } from "framer-motion";
import "./LiaAISection.css";
import { Toastify } from "../Toastify/Toastify";
import { certifier } from "../../mocks/certifier";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { copyText } from "../../services/copyText/copyText";
import { useDispatch, useSelector } from "react-redux";
import {
  setisLiaOpenState,
  enableIsLiaWorking
} from "../../store/slices/globalSlice";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import moment from "moment-timezone";

const loadingMessage = {
  id: "loading",
  type: "loading",
  text: "Lia está digitando...",
  sender: "assistant"
};
const dateCategorys = ["Hoje", "Ontem", "Esta semana", "Este mês"];
const months = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro"
];

export default function LiaAISection({
  classContent,
  windowWidth,
  windowHeight,
  playerHeight,
  studentInfos,
  studentCourses
}) {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.global.isLiaOpen);

  // Refs
  const messagesContainer = useRef(null);
  const textareaDiv = useRef(null);
  const textareaContainer = useRef(null);
  const introducingLia = useRef(null);

  // Internos de controle
  const uniqueKeyCounter = useRef(0);
  const hasMovedRef = useRef(false);

  // States
  const [messageText, setMessageText] = useState("");
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [introducingHeight, setIntroducingHeight] = useState(0);
  const [defaultStatus, setDefaultStatus] = useState({
    suggestionsOptions: ["Resumo da disciplina", "Atividades da disciplina"],
    menu: false,
    chatInUse: false,
    darkChat: false,
    isliaWorking: true,
    currentRun: "",
    waitingRun: false,
    disableTextarea: false,
    newChat: true,
    beforeFirstMessage: true,
    loading: false,
    loadingKey: 0,
    copiedMessage: false,
    liaRegenerateMsgId: ""
  });
  const [status, setStatus] = useState(defaultStatus);
  const [organizedChats, setOrganizedChats] = useState({});
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [firstMessageDate, setFirstMessageDate] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Estado adicional para o foco no mobile:
  const [mobileKeyboardFocus, setMobileKeyboardFocus] = useState(false);

  // -------------------------------------------------------------------
  //                      FUNÇÕES DE ERRO
  // -------------------------------------------------------------------
  function handleError(message, error) {
    console.error(message, error);
    setErrorMessage(message);
    setHasError(true);
    setStatus((prev) => ({
      ...prev,
      disableTextarea: true,
      waitingRun: false,
      loading: false
    }));
  }

  // -------------------------------------------------------------------
  //                     HOOKS E USEEFFECTs
  // -------------------------------------------------------------------

  // Verifica se a Lia está ativa e obtém conversas
  useEffect(() => {
    const handleRequestStatus = async () => {
      try {
        const response = await requestLiaStatus();
        const liaStatus = response.data[0];
        if (liaStatus && typeof liaStatus.liaStatus === "boolean") {
          // Alteração pertecente à atualização de integração ao Joel Jota
          const isliaWorking =
            classContent.disciplineId !== "2391247873"
              ? liaStatus.liaStatus
              : false;
          setStatus((prev) => ({
            ...prev,
            isliaWorking
          }));
          if (isliaWorking) {
            const liaCoursesHistoryEncrypted =
              localStorage.getItem("liaCoursesHistory");
            let liaCoursesHistory = liaCoursesHistoryEncrypted
              ? await CryptoService(liaCoursesHistoryEncrypted, "decrypt-json")
              : [];
            let parsedLiaCoursesHistory = liaCoursesHistory.length
              ? JSON.parse(liaCoursesHistory)
              : [];
            const actualCourse = classContent.name;
            const hasLiaCoursesHistory = parsedLiaCoursesHistory.length
              ? parsedLiaCoursesHistory.some(
                  (course) => course === actualCourse
                )
              : false;
            if (!hasLiaCoursesHistory) {
              setStatus((prev) => ({
                ...prev,
                isliaWorking,
                newChat: false
              }));
            }
            dispatch(enableIsLiaWorking());
            refreshChats();
          }
        }
      } catch (error) {
        handleError(
          "Erro ao verificar status da LIA. Tente novamente mais tarde.",
          error
        );
      }
    };
    handleRequestStatus();

    // eslint-disable-next-line
  }, [classContent]);

  // Rolagem até o final da lista de mensagens, tal como atualizar formatacao de conteudo Matematico
  useEffect(() => {
    if (messagesContainer.current && messages.length > 0) {
      messagesContainer.current.scrollTo({
        top: messagesContainer.current.scrollHeight,
        behavior: "smooth"
      });
    }

    if (window.MathJax) window.MathJax.typeset();
  }, [messages]);

  // Ajusta altura do container de introdução
  useEffect(() => {
    const element = introducingLia.current;
    if (!element) return;
    setIntroducingHeight(element.clientHeight);

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setIntroducingHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Verifica se está aguardando run
  useEffect(() => {
    if (status.waitingRun) handleRunStatus();
    // eslint-disable-next-line
  }, [status]);

  // Reexecuta ao alterar o liaRegenerateMsgId
  useEffect(() => {
    if (status.liaRegenerateMsgId) handleRegenerate(status.liaRegenerateMsgId);
  }, [status.liaRegenerateMsgId]);

  useEffect(() => {
    if (mobileKeyboardFocus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [mobileKeyboardFocus]);

  useEffect(() => {
    if (status.copiedMessage) {
      setTimeout(() => {
        setStatus((prev) => ({ ...prev, copiedMessage: false }));
      }, 2000);
    }
  }, [status.copiedMessage]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [status.menu]);

  useEffect(() => {
    if (isActive) {
      saveCourseLiaAccess();
      setTimeout(() => {
        textareaDiv.current.focus();
      }, 300);
    }
  }, [isActive]);

  // -------------------------------------------------------------------
  //                FUNÇÕES PRINCIPAIS DE MANUSEIO DE CHAT
  // -------------------------------------------------------------------
  const saveCourseLiaAccess = async () => {
    const liaCoursesHistoryEncrypted =
      localStorage.getItem("liaCoursesHistory");
    let liaCoursesHistory = liaCoursesHistoryEncrypted
      ? await CryptoService(liaCoursesHistoryEncrypted, "decrypt-json")
      : [];
    let parsedLiaCoursesHistory = liaCoursesHistory.length
      ? JSON.parse(liaCoursesHistory)
      : [];
    const actualCourse = classContent.name;
    const hasLiaCoursesHistory = parsedLiaCoursesHistory.length
      ? parsedLiaCoursesHistory.some((course) => course === actualCourse)
      : false;
    if (!hasLiaCoursesHistory) {
      const ciphertext = await CryptoService(
        parsedLiaCoursesHistory
          ? JSON.stringify([...parsedLiaCoursesHistory, actualCourse])
          : JSON.stringify([actualCourse]),
        "encrypt-crypto"
      );
      localStorage.setItem("liaCoursesHistory", ciphertext);
    }
  };

  const generateUniqueKey = () => {
    uniqueKeyCounter.current += 1;
    return `msg-${uniqueKeyCounter.current}`;
  };

  const functionExecutors = {
    openCourse: (alias) => {
      window.open(`/course/${alias}`, "_blank");
    },
    openFinance: () => {
      window.open(`/finance`, "_blank");
    },
    openDeclarations: () => {
      window.open(`/user/declarations`, "_blank");
    },
    openDocuments: () => {
      window.open(`/user/documents`, "_blank");
    },
    openContracts: () => {
      window.open(`/user/contracts`, "_blank");
    },
    openCertificates: () => {
      window.open(`/user/certificates`, "_blank");
    },
    openExams: () => {
      window.open(`/avaliations`, "_blank");
    },
    callHumans: () => {
      window.open(
        "https://api.libanoeducacional.com.br/fl-student/wpp-portal",
        "_blank"
      );
    },
    closeAssistant: () => {
      handleCloseLia();
    }
  };

  // Agrupamento de conversas por datas
  const years = Object.keys(organizedChats).filter((label) =>
    /^\d{4}$/.test(label)
  );
  const monthsName = Object.keys(organizedChats).filter((label) =>
    months.includes(label.toLowerCase())
  );

  const orderedCategory = [
    ...dateCategorys.filter((cat) => organizedChats[cat]),
    ...monthsName.sort(
      (a, b) =>
        months.indexOf(a.toLowerCase()) - months.indexOf(b.toLowerCase())
    ),
    ...years.sort((a, b) => b - a)
  ];

  function getDateCollection(attDate) {
    const rightNow = new Date();
    const chatDate = new Date(attDate);

    const startToday = new Date(
      rightNow.getFullYear(),
      rightNow.getMonth(),
      rightNow.getDate()
    );

    const startYesterday = new Date(startToday);
    startYesterday.setDate(startYesterday.getDate() - 1);

    const weekDay = startToday.getDay();
    const displacement = weekDay === 0 ? -6 : 1 - weekDay;
    const startWeek = new Date(startToday);
    startWeek.setDate(startWeek.getDate() + displacement);

    const startMonth = new Date(rightNow.getFullYear(), rightNow.getMonth(), 1);

    const monthsDifference =
      (rightNow.getFullYear() - chatDate.getFullYear()) * 12 +
      (rightNow.getMonth() - chatDate.getMonth());

    if (chatDate >= startToday) {
      return "Hoje";
    } else if (chatDate >= startYesterday && chatDate < startToday) {
      return "Ontem";
    } else if (chatDate >= startWeek && chatDate < startYesterday) {
      return "Esta semana";
    } else if (chatDate >= startMonth && chatDate < startWeek) {
      return "Este mês";
    } else if (monthsDifference < 3) {
      return chatDate.toLocaleString("pt-BR", { month: "long" });
    } else {
      return chatDate.getFullYear().toString();
    }
  }

  // Formata data (caso não seja "Hoje"/"Ontem"/etc)
  function formatDatePTBR(dateObj) {
    return dateObj.toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric"
    });
  }

  // 1) Atualiza lista de chats e reorganiza
  const refreshChats = async () => {
    try {
      const response = await requestLiaChats({
        courseId: classContent.courseId
      });
      const chats = response.data.threads || [];
      chats.sort(
        (a, b) => new Date(b.chatUpdatedAt) - new Date(a.chatUpdatedAt)
      );

      if (
        response.data.quickAiQuestions &&
        response.data.quickAiQuestions.length
      )
        setStatus((prev) => ({
          ...prev,
          suggestionsOptions: response.data.quickAiQuestions
        }));

      setDefaultStatus((prev) => ({
        ...prev,
        suggestionsOptions: response.data.quickAiQuestions
      }));

      const organizedChatsByDate = chats.reduce((acc, chat) => {
        const dateCollection = getDateCollection(chat.chatUpdatedAt);
        if (!acc[dateCollection]) {
          acc[dateCollection] = [];
        }
        acc[dateCollection].push(chat);
        return acc;
      }, {});
      setOrganizedChats(organizedChatsByDate);
    } catch (error) {
      Toastify("error", "Erro ao listar as conversas. Tente novamente.");
      console.error("Erro ao atualizar lista de conversas:", error);
    }
  };

  // 2) Verifica status do run a cada 1s (até completar)
  const handleRunStatus = async () => {
    const currentRun = status.currentRun;
    if (!currentRun) return;
    const response = await requestLiaRunStatus({
      threadId: currentChat?.id,
      runId: currentRun
    });
    if (response && response.data && response.data.status === "completed") {
      setStatus((prev) => ({
        ...prev,
        waitingRun: false,
        disableTextarea: false
      }));
      const messagesWithoutLoading = messages.filter(
        (msg) => msg.id !== "loading"
      );
      setMessages(messagesWithoutLoading);

      setTimeout(() => {
        const newMessage = {
          id: response.data.liaMessageId,
          uniqueKey: generateUniqueKey(),
          type: "text",
          text: response.data.liaMessage.split("[EXECUTE-START]")[0],
          sender: "assistant"
        };
        if (status.beforeFirstMessage) refreshChats();
        setStatus((prev) => ({
          ...prev,
          currentRun: "",
          beforeFirstMessage: false
        }));
        setMessages([...messagesWithoutLoading, newMessage]);
        if (isActive) textareaDiv.current.focus();

        // Caso haja execução de função
        if (
          response.data.liaMessage.includes("[EXECUTE-START]") &&
          response.data.liaMessage.includes("[EXECUTE-END]")
        ) {
          const startIndex =
            response.data.liaMessage.indexOf("[EXECUTE-START]") + 15;
          const endIndex = response.data.liaMessage.indexOf("[EXECUTE-END]");
          const snippet = response.data.liaMessage
            .substring(startIndex, endIndex)
            .trim();

          const functionName = snippet
            .substring(0, snippet.indexOf("("))
            .trim();
          let paramsRaw = snippet.substring(
            snippet.indexOf("(") + 1,
            snippet.lastIndexOf(")")
          );
          paramsRaw = paramsRaw.replace(/['"]/g, "").trim();

          if (functionExecutors[functionName]) {
            functionExecutors[functionName](paramsRaw);
          }
        }
      }, 300);
    } else {
      setTimeout(() => {
        handleRunStatus();
      }, 1000);
    }
  };

  // 3) Abre/fecha menu lateral
  const handleMenu = () => {
    if (status.disableTextarea || hasError) return;
    const newStatus = !status.menu;
    setStatus((prev) => ({
      ...prev,
      menu: newStatus,
      darkChat: newStatus
    }));
  };

  // 4) Fechar Assistente
  const handleCloseLia = () => {
    dispatch(setisLiaOpenState(false));
  };

  // 5) Enviar mensagem (usuário -> Lia)
  const sendMessage = async (overrideMessage) => {
    if (status.waitingRun || hasError) return;
    const textMessage = overrideMessage
      ? overrideMessage.trim()
      : messageText.trim();
    if (!textMessage) return;
    setMessageText("");

    const newMessageUser = {
      id: "temp",
      uniqueKey: generateUniqueKey(),
      type: "text",
      text: textMessage,
      sender: "user"
    };

    if (messages.length === 0) {
      const now = new Date();
      const isToday = now.toDateString() === new Date().toDateString();
      setFirstMessageDate(isToday ? "Hoje" : formatDatePTBR(now));
    }
    setMessages((prev) => [...prev, newMessageUser]);

    // Adiciona loading
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { ...loadingMessage, uniqueKey: generateUniqueKey() }
      ]);
    }, Number((Math.random() * (1600 - 500) + 500).toFixed(2)));

    setStatus((prev) => ({ ...prev, chatInUse: true, darkChat: false }));
    textareaDiv.current.value = "";
    textareaDiv.current.style.height = "auto";

    try {
      setStatus((prev) => ({
        ...prev,
        disableTextarea: true
      }));
      const systemPrompt = getSystemPrompt();
      const existingThreadId = currentChat ? currentChat.id : false;

      const response = await requestNewLiaMessage({
        message: textMessage,
        courseId: classContent.courseId,
        existingThreadId,
        userInfos: existingThreadId ? undefined : systemPrompt
      });

      if (!existingThreadId) {
        const newChat = {
          id: response.data.threadId,
          assistantId: response.data.assistantId,
          name: response.data.chatTitle,
          createdAt: new Date().toISOString(),
          chatUpdatedAt: new Date().toISOString(),
          firstMessage: textMessage
        };
        setCurrentChat(newChat);
      }

      setMessages((prev) =>
        prev.map((msg) =>
          msg.uniqueKey === newMessageUser.uniqueKey
            ? { ...msg, id: response.data.userMessage }
            : msg
        )
      );

      setStatus((prev) => ({
        ...prev,
        currentRun: response.data.runId,
        waitingRun: true
      }));
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);
      Toastify("error", "Erro ao enviar mensagem. Tente novamente mais tarde.");
      setMessages((prev) => prev.filter((msg) => msg.id !== "loading"));
      setStatus((prev) => ({
        ...prev,
        waitingRun: false,
        disableTextarea: false
      }));
    }
  };

  // 6) Regerar mensagem da Lia
  const handleRegenerate = async (liaMsgId) => {
    try {
      if (!currentChat?.id || status.disableTextarea) return;
      setStatus((prev) => ({
        ...prev,
        disableTextarea: true
      }));
      setMessages((prev) => prev.filter((msg) => msg.id !== liaMsgId));

      setTimeout(() => {
        setMessages((prev) => [...prev, loadingMessage]);
      }, 600);

      const response = await requestRegenerateLiaMessage({
        threadId: currentChat.id,
        courseId: classContent.courseId,
        liaMessageId: liaMsgId
      });

      if (!response?.data?.result) {
        throw new Error("Erro ao regerar mensagem.");
      }

      setCurrentChat((prev) => ({
        ...prev,
        id: response.data.result.newThreadId
      }));

      setStatus((prev) => ({
        ...prev,
        currentRun: response.data.result.newRunId,
        disableTextarea: true,
        waitingRun: true,
        liaRegenerateMsgId: ""
      }));
    } catch (error) {
      setMessages((prev) => prev.filter((msg) => msg.id !== "loading"));
      handleError(
        "Houve um erro no processamento da Lia. Tente novamente mais tarde!",
        error
      );

      setStatus((prev) => ({
        ...prev,
        waitingRun: false,
        disableTextarea: true,
        liaRegenerateMsgId: ""
      }));
    }
  };

  // 7) Ações do textarea
  const handleTextAreaChange = (e) => {
    setMessageText(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = `${
      e.target.scrollHeight > 64 ? 64 : e.target.scrollHeight
    }px`;
  };

  // Detectar Enter (sem Shift)
  const handleTextAreaKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  // Sugestões iniciais
  const handleOptionClick = (option) => (e) => {
    if (hasMovedRef.current) {
      e.preventDefault();
      return;
    }
    sendMessage(option);
  };

  // 8) Abrir chat específico
  const handleChatClick = async (chat) => {
    try {
      // Se for o mesmo chat, apenas fecha o menu
      if (chat.id === currentChat?.id) {
        setStatus((prev) => ({
          ...prev,
          menu: false,
          darkChat: false,
          chatInUse: true
        }));
        return;
      }
      setDisableAnimation(true);
      setStatus((prev) => ({
        ...prev,
        loading: true,
        loadingKey: prev.loadingKey + 1,
        beforeFirstMessage: true
      }));

      setMessages([]);
      const response = await requestSpecifiedLiaChat({
        threadId: chat.id
      });
      setCurrentChat(chat);
      if (response?.data?.messages) {
        const newMessages = response.data.messages
          .reverse()
          .slice(1)
          .map((msg, idx) => {
            let textContent = "";
            if (Array.isArray(msg.content)) {
              const textBlocks = msg.content
                .filter((item) => item.type === "text")
                .map((item) => item.text.value);
              textContent = textBlocks.join("\n");
            }
            const sender = msg.role === "assistant" ? "assistant" : "user";
            return {
              id: msg.id,
              type: "text",
              text: textContent.split("[EXECUTE-START]")[0],
              sender
            };
          });

        setMessages(newMessages);

        if (newMessages.length > 0) {
          const firstMsg = response.data.messages[1];
          if (firstMsg) {
            const dateMsg = new Date(firstMsg.created_at * 1000);
            const now = new Date();
            const isToday = now.toDateString() === dateMsg.toDateString();
            setFirstMessageDate(isToday ? "Hoje" : formatDatePTBR(dateMsg));
          } else {
            setFirstMessageDate("");
          }
        } else {
          setMessages([]);
          setFirstMessageDate("");
        }
      } else {
        setMessages([]);
        setFirstMessageDate("");
      }

      setStatus((prev) => ({
        ...prev,
        menu: false,
        darkChat: false,
        chatInUse: true
      }));
      setTimeout(() => {
        setStatus((prev) => ({
          ...prev,
          loading: false
        }));
      }, 300);
      setMessageText("");
      textareaDiv.current.focus();

      setTimeout(() => {
        if (messagesContainer.current) {
          messagesContainer.current.scrollTo({
            top: messagesContainer.current.scrollHeight,
            behavior: "auto"
          });
        }
      }, 0);

      setTimeout(() => {
        setDisableAnimation(false);
      }, 0);
    } catch (error) {
      handleError(
        "Houve um erro ao abrir a conversa. Tente novamente mais tarde!",
        error
      );
      setStatus((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  // 9) Nova conversa
  const handleNewChat = () => {
    setStatus((prev) => ({
      ...prev,
      loading: true,
      loadingKey: prev.loadingKey + 1
    }));
    setTimeout(() => {
      const internalDefaultStatus = { ...defaultStatus };
      internalDefaultStatus.newChat = true;
      internalDefaultStatus.loading = true;
      internalDefaultStatus.loadingKey = status.loadingKey + 1;
      setStatus(internalDefaultStatus);
      setMessages([]);
      setCurrentChat(null);
      setFirstMessageDate("");
      setTimeout(() => {
        setStatus((prev) => ({
          ...prev,
          loading: false,
          beforeFirstMessage: true
        }));
        setMessageText("");
        textareaDiv.current.focus();
      }, 300);
    }, 300);
  };

  // 10) Monta system prompt
  const getSystemPrompt = () => {
    const text = `[SISTEMA - INÍCIO] O Aluno é ${studentInfos.name}${
      studentInfos.city ? `, da cidade ${studentInfos.city}` : ""
    }. Ele está na disciplina ${
      classContent.disciplineName
    }, especificamente na aula ${
      classContent.currentClass.name
    }. Descrição da aula: ${
      classContent.currentClass.description
    }. Ele possui os cursos: ${
      studentCourses
        ? studentCourses
            .map((course) => {
              return `'${course.name}' - Alias: '${course.alias}'`;
            })
            .join("; ")
        : "(não informado)"
    }

Data e horário de início da conversa: ${moment.tz('America/Sao_Paulo').format('DD/MM/YYYY - HH:mm:ss')}

Atenção: Você não pode informar ao usuário na sua resposta qual é o alias do curso, SOMENTE retornar o alias na execução de função caso necessário. 
Quando entrar no contexto de pedir redirecionamento, você pode retornar uma mensagem como 'Claro!' (ou similares, não somente "Claro"), e em seguida escrever "[EXECUTE-START]<função e parametro>[EXECUTE-END]". Exemplo: "Tudo bem. [EXECUTE-START]openCourse('alias-do-curso')[EXECUTE-END]".

Funções disponíveis:
1- openCourse(<alias>) // Abre o curso em uma nova aba.
2- openFinance() // Abre o financeiro do aluno.
3- openDeclarations() // Abre a aba de declaração do aluno.
4- openDocuments() // Abre a aba de documentos do aluno.
5- openContracts() // Abre a aba de contratos do aluno. 
6- openCertificates() // Abre a aba de certificados do aluno.
7- openExams() // Abre a aba de provas do aluno.
8- callHumans() // Abre o WhatsApp da equipe de atendimento, útil quando uma ação específica deve ser realizada, você poderá sugerir para que abra o WhatsApp de comunicação com a equipe humana de atendimento.
9- closeAssistant() // Fecha o assistente.

[SISTEMA - FIM]`;
    return text;
  };

  // -------------------------------------------------------------------
  //                   FUNÇÕES DE RENDERIZAÇÃO
  // -------------------------------------------------------------------

  const renderLiaLogo = (disableInitialAnimation, disableExitAnimation) => {
    return (
      <motion.div
        initial={
          disableAnimation || disableInitialAnimation
            ? { opacity: 1 }
            : { opacity: 0 }
        }
        animate={{ opacity: 1 }}
        exit={disableExitAnimation ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="absolute z-[8] top-3 left-0 flex items-center justify-center w-8 h-8 rounded-full bg-backgroundTwo">
        <img
          src="/images/logos/lia-light-logo-v2.webp"
          alt="Lia Logo"
          className="w-4 h-auto"
        />
      </motion.div>
    );
  };

  const handleCopyMessage = (message) => {
    if (status.copiedMessage) return;
    copyText(message.text, true);
    setStatus((prev) => ({ ...prev, copiedMessage: true }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      if (status.menu) {
        setStatus((prev) => ({ ...prev, menu: false, darkChat: false }));
      } else {
        handleCloseLia();
      }
    }
  };

  const processLinks = (text) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const elements = [];
    let lastIndex = 0;
    let match;
    let keyCounter = 0;

    while ((match = linkRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        elements.push(text.substring(lastIndex, match.index));
      }
      elements.push(
        <a
          className="underline"
          target={"_blank"}
          rel="noreferrer"
          key={`link-${keyCounter}`}
          href={match[2]}>
          {match[1]}
        </a>
      );
      keyCounter++;
      lastIndex = match.index + match[0].length;
    }
    if (lastIndex < text.length) {
      elements.push(text.substring(lastIndex));
    }
    return elements;
  };

  const processText = (text) => {
    // 1. Processa código inline: procura por trechos entre uma única crase
    const inlineCodeRegex = /`([^`]+)`/g;
    let segments = [];
    let lastIndex = 0;
    let match;
    while ((match = inlineCodeRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        segments.push(text.slice(lastIndex, match.index));
      }
      segments.push(
        <code key={`code-${match.index}`} className="inline-code">
          {match[1]}
        </code>
      );
      lastIndex = match.index + match[0].length;
    }
    if (lastIndex < text.length) {
      segments.push(text.slice(lastIndex));
    }

    // 2. Processa links em cada segmento que for string.
    segments = segments.flatMap((segment) => {
      if (typeof segment === "string") {
        return processLinks(segment);
      }
      return segment;
    });

    // 3. Processa negrito (**) em cada segmento que for string.
    return segments.flatMap((segment, index) => {
      if (typeof segment === "string") {
        return segment
          .split("**")
          .map((subsegment, subIndex) =>
            subIndex % 2 === 1 ? (
              <strong key={`bold-${index}-${subIndex}`}>{subsegment}</strong>
            ) : (
              subsegment
            )
          );
      }
      return segment;
    });
  };

  const renderLine = (line) => {
    if (line.trim() === "---") return <hr />;

    const headingRegex = /^(#{3,4})\s*(.*)$/;
    const headingMatch = line.match(headingRegex);
    if (headingMatch) {
      const headingText = headingMatch[2];
      return <strong>{processText(headingText)}</strong>;
    }

    const listItemRegex = /^(\d+)\.\s+(.*)/;
    const match = line.match(listItemRegex);
    if (match) {
      const itemContent = match[2];
      return <li key={match[1]}>{processText(itemContent)}</li>;
    }

    return processText(line);
  };

  const parseMessageText = (text) => {
    const lines = text.split("\n");
    const elements = [];
    let inCodeBlock = false;
    let codeBlockLanguage = "";
    let codeBlockLines = [];

    lines.forEach((line, index) => {
      if (line.startsWith("```")) {
        if (!inCodeBlock) {
          inCodeBlock = true;
          codeBlockLanguage = line.replace(/^```(\w+)?/, "$1").trim();
          codeBlockLines = [];
        } else {
          inCodeBlock = false;
          const codeContent = codeBlockLines.join("\n");
          elements.push(
            <div key={`code-${index}`} className="my-2">
              <div
                className="overflow-hidden rounded-lg w-fit grid"
                style={{ marginBottom: 0 }}>
                <div
                  className="flex items-center justify-between w-full bg-gray-800 text-gray-200 p-2 text-xs"
                  style={{ marginBottom: 0, marginTop: 0 }}>
                  <code className="leading-none">
                    {codeBlockLanguage || "code"}
                  </code>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleCopyMessage({ text: codeContent })}>
                    <HiOutlineSquare2Stack size={14} />
                  </span>
                </div>
                <div
                  className="overflow-x-auto bg-backgroundTwo custom-dark-scroll custom-small-horizontal-scroll"
                  style={{ marginBottom: 0, marginTop: 0 }}>
                  <pre className={`language-${codeBlockLanguage} p-2`}>
                    <code>{codeContent}</code>
                  </pre>
                </div>
              </div>
            </div>
          );
        }
      } else if (inCodeBlock) {
        codeBlockLines.push(line);
      } else {
        elements.push(<div key={`line-${index}`}>{renderLine(line)}</div>);
      }
    });

    return elements;
  };

  const renderMessage = (message, isUser, index, invisible) => {
    try {
      return (
        <div
          aria-hidden={invisible ? true : undefined}
          className={
            invisible
              ? "opacity-0 pointer-events-none select-none"
              : `absolute top-0 ${isUser ? "right-0" : "left-0"}`
          }>
          <motion.div
            transition={{ duration: 0.3 }}
            className={`flex flex-col w-fit h-fit p-4 leading-relaxed rounded-2xl ${
              isUser ? "bg-backgroundTwo" : "bg-backgroundThree"
            }`}>
            {!isUser ? (
              <span
                className="text-sm assistant-message-text"
                style={{ overflowWrap: "anywhere" }}>
                {parseMessageText(message.text).map((element, idx, arr) => (
                  <motion.div
                    key={idx}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: disableAnimation ? 0 : idx * 0.05,
                      delay: disableAnimation ? 0 : idx * 0.05
                    }}
                    style={{
                      display: "block",
                      marginBottom: arr.length === idx + 1 ? "0" : undefined
                    }}>
                    {element}
                  </motion.div>
                ))}
              </span>
            ) : (
              <span
                className="text-sm leading-[1.75]"
                style={{ overflowWrap: "anywhere" }}>
                {message.text}
              </span>
            )}

            {/* Botões de regerar e copiar (apenas no último msg assistente) */}
            {!isUser && messages.length === index + 1 && !invisible && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="absolute -bottom-2 left-4">
                <div className="flex gap-3 mt-1.5 text-textSecondary">
                  <button
                    onClick={() =>
                      setStatus((prev) => ({
                        ...prev,
                        liaRegenerateMsgId: message.id
                      }))
                    }
                    disabled={status.liaRegenerateMsgId}>
                    <HiOutlineArrowPathRoundedSquare size={18} />
                  </button>
                  <button
                    className={
                      status.copiedMessage ? "pointer-events-none" : ""
                    }
                    onClick={() => handleCopyMessage(message)}>
                    {status.copiedMessage ? (
                      <HiMiniCheck size={18} />
                    ) : (
                      <HiOutlineSquare2Stack size={18} />
                    )}
                  </button>
                </div>
              </motion.div>
            )}
          </motion.div>
        </div>
      );
    } catch (error) {
      handleError(
        "Houve um erro ao processar as mensagens da conversa. Tente novamente mais tarde!",
        error
      );
    }
  };

  // -------------------------------------------------------------------
  //                        RETORNO DO COMPONENTE
  // -------------------------------------------------------------------
  return (
    <section
      className={`absolute left-0 z-[14] w-full h-full overflow-hidden ${
        !isActive && "pointer-events-none"
      } max-lg:fixed`}
      style={{
        top: windowWidth <= 1024 ? undefined : "0",
        bottom: windowWidth <= 1024 ? "60px" : undefined,
        height:
          windowWidth <= 1024
            ? `${windowHeight - playerHeight - 60 + 1}px`
            : `100%` ////////////-----///////////////
        // : `${playerHeight}px` ////////////-----///////////////
      }}>
      <section
        className={`absolute top-0 left-0 z-[14] flex flex-col justify-between w-full h-full lg:rounded-lg overflow-hidden transition-600 ${
          !isActive && "translate-x-[100%] pointer-events-none"
        }`}>
        {/* Loading overlay */}
        <div
          className={`absolute top-0 left-0 flex justify-center items-center w-full h-full backdrop-blur-sm bg-black/10 z-[15] transition-300 ${
            status.loading ? "" : "pointer-events-none opacity-0"
          }`}>
          <img
            src={`/images/logos/lia-loading-light.gif?restartCache=${status.loadingKey}`}
            className="w-auto h-32"
            alt="Lia Logo"
          />
        </div>

        {/* Header */}
        <div
          className="grid grid-cols-8 w-full p-4 bg-backgroundTwo min-h-[56px]"
          style={{
            filter:
              "drop-shadow(0 0px 0px rgb(0 0 0 / 0.3)) drop-shadow(0 0px 4px rgb(0 0 0 / 0.3))"
          }}>
          <div className="col-span-1 flex gap-6">
            <div
              className={`w-fit ${
                status.disableTextarea || hasError
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={handleMenu}>
              <HiBars3CenterLeft size={24} />
            </div>
          </div>
          <div className="col-span-6 relative pointer-events-none text-base font-medium select-none">
            <span
              className={`absolute pt-[5px] transition-300 cursor-pointer leading-none ${
                status.menu ? "" : "opacity-0"
              }`}>
              Histórico de Conversas
            </span>
            <span
              className={`absolute pt-[5px] transition-300 cursor-pointer leading-none line-clamp-1 break-all ${
                !status.menu && currentChat && currentChat.name
                  ? ""
                  : "opacity-0"
              }`}>
              {currentChat && currentChat.name}
            </span>
          </div>
          <div className="flex justify-end col-span-1">
            <span
              className={`absolute transition-300 cursor-pointer`}
              onClick={status.menu ? handleMenu : handleCloseLia}>
              <IoClose size={24} />
            </span>
          </div>
        </div>

        {/* Corpo principal */}
        <div className="flex w-full h-full bg-backgroundThree text-textPrimary relative">
          {hasError ? (
            <section
              className={`
              absolute top-0 left-0 z-[15] flex flex-col justify-between
              w-full h-full lg:rounded-lg overflow-hidden transition-600
            `}>
              <div className="flex flex-col w-full h-full bg-backgroundThree items-center justify-center">
                <div className="flex flex-col gap-6 p-10 w-full h-full max-lg:p-4">
                  <img
                    src="/images/logos/lia-light-logo-v2.webp"
                    className="w-10 h-auto cursor-pointer select-none transition-300 hover:scale-105 active:scale-90"
                    alt="Lia Logo"
                  />
                  <div className="flex flex-col gap-3">
                    <span className="font-semibold text-[1.5rem] text-left w-full leading-tight max-lg:text-[1.25rem]">
                      Ocorreu um{" "}
                      <span className="lia-gradient font-bold">erro</span>.
                    </span>
                    <span className="text-sm">{errorMessage}</span>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <>
              {/* Introdução da Lia (tela inicial) */}
              <div
                ref={introducingLia}
                className={`absolute top-0 left-0 flex w-full h-full justify-center items-center transition-300 bg-backgroundThree ${
                  status.chatInUse ? "opacity-0 pointer-events-none" : ""
                }`}>
                {status.newChat ? (
                  <div className="flex flex-col gap-6 p-10 h-full">
                    <div className="flex flex-col gap-3">
                      <span className="font-semibold text-[1.5rem] text-left w-full leading-tight max-lg:text-[1.25rem]">
                        <span className="lia-gradient">
                          Olá, {studentInfos.name.split(" ")[0]}!
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-6 p-10 w-full h-full max-lg:p-4">
                    <img
                      src="/images/logos/lia-loading-light.gif"
                      className="w-[5rem] ml-[-1.2rem] mb-[-1rem] h-auto cursor-pointer select-none transition-300 hover:scale-105 active:scale-90"
                      alt="Lia Logo"
                    />
                    <div className="flex flex-col gap-3">
                      <span className="font-semibold text-[1.5rem] text-left w-full leading-tight max-lg:text-[1.25rem]">
                        Conheça a{" "}
                        <span className="lia-gradient font-bold">Lia</span>, sua
                        nova agente de estudo d
                        {certifier.generalSettings.grammatical.pronoun}{" "}
                        {certifier.generalSettings.shortName}
                      </span>
                      <span className="text-sm">
                        Conte comigo para aprender e discutir ideias sobre o
                        conteúdo do seu curso.
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {/* Botões de sugestão (tela inicial) */}
              <div
                className={`absolute z-[8] bottom-0 left-0 flex w-full justify-center items-center transition-300 ${
                  status.chatInUse ? "opacity-0 pointer-events-none" : ""
                }`}>
                <div className="flex w-full">
                  {status.newChat && (
                    <div className="flex flex-col gap-3 pb-3 w-full">
                      {status.suggestionsOptions.map((option, index) => (
                        <div
                          key={index}
                          className={`flex flex-col gap-3 px-2 ${
                            index >= 1 && windowHeight <= 741 && "hidden"
                          }`}>
                          <button
                            className={`py-3 transition-300 px-6 w-full text-sm rounded-full leading-[1.75] hover:bg-[#131418]`}
                            onClick={handleOptionClick(option)}>
                            {option}
                          </button>
                          {index !== status.suggestionsOptions.length - 1 ? (
                            <div
                              className={`flex justify-center items-center `}>
                              <div className="w-full h-[1px] bg-white/20" />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Container de mensagens */}
              <div
                ref={messagesContainer}
                className={`absolute top-0 left-0 flex flex-col w-full h-full overflow-y-auto overflow-x-hidden custom-dark-scroll custom-transparent-scroll-background p-4 pb-0 ${
                  !status.chatInUse && "pointer-events-none"
                }`}
                style={{ height: `${introducingHeight}px` }}>
                <AnimatePresence initial={false}>
                  {/* Data inicial da primeira mensagem */}
                  {messages.length > 0 && firstMessageDate && (
                    <motion.div
                      key="date-label"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="flex w-full justify-center items-center mb-4">
                      <div className="px-3 py-1 bg-backgroundTwo rounded-md text-xs text-textSecondary">
                        {firstMessageDate}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>

                <AnimatePresence>
                  {messages.map((message, index) => {
                    const isUser = message.sender === "user";
                    if (message.type === "loading") {
                      return (
                        <div
                          key={message.uniqueKey}
                          className="flex w-full justify-start items-start relative">
                          {renderLiaLogo(false, true)}
                          <motion.div
                            className="max-w-[80%] md:max-w-[90%] xl:max-w-[80%] relative"
                            initial={
                              disableAnimation
                                ? { height: "auto", opacity: 1 }
                                : { height: 0, opacity: 0 }
                            }
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}>
                            <div className="flex items-center gap-3 p-3 pl-12 mt-1.5 rounded-2xl bg-backgroundThree">
                              <span className="text-sm relative ">
                                <span className="opacity-0">
                                  Lia está digitando...
                                </span>
                                <span className="text-sm text-textSecondary shine-text">
                                  Lia está digitando...
                                </span>
                              </span>
                            </div>
                          </motion.div>
                        </div>
                      );
                    }

                    return (
                      <div
                        key={index}
                        className={`flex w-full mb-3 ${
                          isUser ? "justify-end" : "justify-start pl-8"
                        } items-start relative`}>
                        {!isUser && renderLiaLogo(true)}

                        <motion.div
                          className="max-w-[80%] md:max-w-[90%] xl:max-w-[80%] relative"
                          initial={
                            disableAnimation
                              ? { height: "auto", opacity: 1, y: 0 }
                              : {
                                  height: isUser ? 0 : "auto",
                                  opacity: 0,
                                  y: isUser ? 50 : 0
                                }
                          }
                          animate={{ height: "auto", opacity: 1, y: 0 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3 }}>
                          {/* Mensagem invisível (para transição) */}
                          {renderMessage(message, isUser, index, true)}
                          {/* Mensagem visível */}
                          {renderMessage(message, isUser, index, false)}
                        </motion.div>
                      </div>
                    );
                  })}
                </AnimatePresence>
              </div>
            </>
          )}
        </div>

        {/* Área do TextArea para enviar mensagem */}
        <div
          className="flex flex-col w-full bg-backgroundThree pb-[24px]"
        >
          {status.isliaWorking && (
            <>
              <div
                ref={textareaContainer}
                className="p-3 bg-backgroundThree w-full h-fit">
                <div className="flex w-full h-fit bg-backgroundTwo rounded-2xl items-center overflow-hidden relative py-5">
                  <textarea
                    ref={textareaDiv}
                    id="messageTextarea"
                    className="bg-transparent w-full pl-5 pr-[3.5rem] resize-none overflow-hidden transition-300 text-sm"
                    rows="1"
                    disabled={status.disableTextarea}
                    placeholder={
                      !messages || !messages.length ? "Converse com a Lia" : ""
                    }
                    maxLength={1000}
                    onInput={(e) => {
                      // e.target.style.height = "auto";
                      // e.target.style.height = `${
                      //   e.target.scrollHeight > 64 ? 64 : e.target.scrollHeight
                      // }px`;
                    }}
                    onChange={handleTextAreaChange}
                    onKeyDown={handleTextAreaKeyDown}
                    value={messageText}
                    // Quando focar em mobile, aciona o state:
                    onFocus={() => {
                      if (windowWidth <= 1024) setMobileKeyboardFocus(true);
                    }}
                    // Quando perder foco, reverte:
                    onBlur={() => {
                      if (windowWidth <= 1024) setMobileKeyboardFocus(false);
                    }}
                  />
                </div>
              </div>

              {/* Botão de enviar (seta) */}
              <div
                className="absolute bottom-[48px] right-[22px] flex w-fit h-fit items-end p-1"
                // style={{ bottom: windowWidth < 1600 ? "40px" : "40px" }}
              >
                <div
                  className={`flex transition-300 ${
                    messageText.trim() && !status.disableTextarea && !hasError
                      ? "cursor-pointer -rotate-90"
                      : "cursor-not-allowed opacity-50"
                  }`}
                  onClick={() => {
                    if (messageText.trim()) {
                      sendMessage();
                    }
                  }}>
                  <BsFillArrowRightCircleFill size={28} />
                </div>
              </div>
            </>
          )}

          <div className="absolute bottom-0 left-0 flex w-full py-3 justify-center">
            <span className="text-xs text-textSecondary leading-none font-normal text-center px-2">
              Considere verificar informações.
            </span>
          </div>
        </div>

        {/* Escurecer tela ao abrir Menu */}
        <div
          onClick={handleMenu}
          className={`absolute z-[10] top-[3.5rem] left-0 w-full h-[calc(100%-3.5rem)] bg-black/50 transition-300 ${
            status.darkChat ? "" : "opacity-0 pointer-events-none"
          }`}
        />

        {/* Menu Lateral */}
        <div
          id="menuBar"
          className={`absolute z-[11] top-[3.5rem] left-0 w-full lg:w-[85%] xl:w-[75%]  h-[calc(100%-3.5rem)] bg-backgroundThree transition-600 ${
            status.menu ? "" : "left-[-100%]"
          }`}>
          <div className="flex flex-col w-full h-full py-8 gap-8">
            <div className="px-4">
              <ButtonPrimary
                optionalTextColor={
                  certifier.colors.buttons.buttonTertiary_darkTheme
                    ? "#fff"
                    : "#000"
                }
                textButton="Nova Conversa"
                disableHorizontalMovement
                optionalBackground={certifier.colors.buttons.buttonTertiary}
                mobileWidth="100%"
                desktopWidth="100%"
                verifyOnClick={handleNewChat}
              />
            </div>

            {Object.keys(organizedChats).length > 0 && (
              <div className="flex flex-col w-full h-full gap-3 px-4 overflow-y-scroll overflow-x-hidden custom-dark-scroll custom-transparent-scroll-background">
                {orderedCategory.map((category, index) => (
                  <div
                    key={index}
                    className="flex flex-col w-full gap-[1.375rem] pb-6">
                    <div className="flex w-full">
                      <span className="text-base leading-tight font-medium">
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </span>
                    </div>
                    <div className="flex flex-col gap-[1.375rem] ml-3">
                      {organizedChats[category].map((chat) => (
                        <div
                          onClick={() => handleChatClick(chat)}
                          key={chat.id}
                          className="flex w-fit select-none cursor-pointer">
                          <span className="text-sm">{chat.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </section>
  );
}
