import React, { useState, useEffect, useRef } from "react";
import "./OriginalCoursesCard.css";
import { Link } from "react-router-dom";
import { HiLockClosed, HiLockOpen } from "react-icons/hi2";
import { certifier } from "../../../mocks/certifier";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { handlePurchaseCourse } from "../../../services/dashboardService/dashboard.service";
import getStudentInfos from "../../../services/getStudentInfos/getStudentInfos";
import RescueCourseModal from "../../Modals/RescueCourseModal";

function OriginalCoursesCard({
  smallCard,
  image,
  typeCourse,
  name,
  teacher,
  redirectTo,
  onClick,
  forceVerticalResolution,
  videoFirstFrame,
  courseId,
  courseStars
}) {
  // const [purchaseLoading, setPurchaseLoading] = useState(false);
  // const [pointerEventsDisabled, setPointerEventsDisabled] = useState(false);
  const [openRescuePopUp, setOpenRescuePopUp] = useState(false);
  const [newCourseHover, setNewCourseHover] = useState(false);
  const cardRef = useRef(null);

  const isRescueCourse = redirectTo?.includes("/rescueCourse");
  const isPurchaseCourse = redirectTo?.includes("/purchaseCourse");
  const isPurchaseOrRescueCourse = isPurchaseCourse || isRescueCourse;
  const isPostGraduation = typeCourse === "Pós-Graduação";
  // const isCoursePage = redirectTo?.includes("/course/");
  // const isViewCourse = isPostGraduation || isRescueCourse || isCoursePage;
  // const showPurchaseButton = !isCoursePage;

  const marginCard = {
    transitionTimingFunction: "ease-out",
    transitionProperty: "transform",
    transitionDuration: "300ms",
    cursor: !redirectTo && "pointer"
  };

  function undoTranslateTypeCourse(typeCourse) {
    const translatedTypeCourse = {
      "Pós-Graduação": "posGraduacao",
      "Cursos Livres": "cursosLivres"
    };
    return translatedTypeCourse[typeCourse] || typeCourse;
  }

  const courseAlias = isRescueCourse ? redirectTo.split("/")[3] : null;

  const WrapperComponent = redirectTo ? Link : "div";
  const wrapperProps = redirectTo
  // ? { to: (!isPostGraduation && redirectTo.includes("/purchaseCourse")) ? "" : redirectTo, reloadDocument: true }
  // Descomente a linha acima pra retornar o bloqueio de curso livre para compra no portal
  ? { to: redirectTo, reloadDocument: true }
  : { onClick: onClick && onClick };
  // const WrapperComponent = "div";
  // const wrapperProps = { onClick: onClick && onClick };

  // const handleCourseHover = (hover) => {
  //   setNewCourseHover(hover);
  // };
  // const handleInteraction = () => {
  //   setTimeout(() => {
  //     setPointerEventsDisabled(true);
  //   }, 300);
  // };
  // const resetPointerEvents = () => {
  //   setPointerEventsDisabled(false);
  // };

  return (
    <div className="flex w-full justify-center relative">
      <RescueCourseModal
        isOpen={openRescuePopUp}
        onClose={() => setOpenRescuePopUp(false)}
        courseName={name}
        courseAlias={courseAlias}
        typeCourse={undoTranslateTypeCourse(typeCourse)}
        getStudentData={getStudentInfos}
        redirectUrl={`/course/${courseAlias}`}
      />
      <section className="w-full flex flex-col h-auto">
        <WrapperComponent
          {...wrapperProps}
          ref={cardRef}
          style={marginCard}
          className={`my-3 flex flex-col relative h-auto justify-end z-10 group/card rounded-[0.8rem]`}>
          <div
            className="absolute top-0 left-0 w-full rounded-[0.8rem] z-[1] opacity-0 transition-600 group-hover/card:opacity-100"
            style={{ aspectRatio: 9 / 16 }}>
            {/* <div className="flex flex-col justify-end w-full h-full p-1 bg-gradient-to-t from-black/80 to-transparent">
              <div className="flex flex-col gap-3 w-full h-fit p-3 pb-6">
                {(isPostGraduation || isRescueCourse) && showPurchaseButton && (
                  <div className="flex w-full ease-out transition-600 translate-y-3 group-hover/card:translate-y-0">
                    <ButtonPrimary
                      textButton={isRescueCourse ? "Resgatar" : "Adquirir"}
                      verifyOnClick={async () => {
                        if (isRescueCourse) {
                          setOpenRescuePopUp(true);
                        } else {
                          await handlePurchaseCourse(
                            courseId,
                            setPurchaseLoading
                          );
                        }
                      }}
                      desktopWidth="100%"
                      mobileWidth="100%"
                      outlineType
                      outlineColor={certifier.colors.text.textPrimary}
                      optionalTextColor={certifier.colors.text.textPrimary}
                      disabled={!isRescueCourse && purchaseLoading}
                      onHoverState={handleCourseHover}
                    />
                  </div>
                )}
                {isViewCourse && (
                  <Link
                    className="flex w-full transition-600 ease-out translate-y-5 opacity-0 group-hover/card:delay-75 group-hover/card:translate-y-0 group-hover/card:opacity-100"
                    to={redirectTo}
                    reloadDocument>
                    <ButtonPrimary
                      textButton="Visualizar Curso"
                      desktopWidth="100%"
                      mobileWidth="100%"
                    />
                  </Link>
                )}
                <div
                  className={`absolute top-0 left-0 w-full h-full z-[2] ${
                    pointerEventsDisabled
                      ? "pointer-events-none"
                      : "pointer-events-auto"
                  } md:hidden`}
                  onClick={handleInteraction}
                  onMouseLeave={resetPointerEvents}
                  onTouchStart={handleInteraction}
                  onTouchEnd={resetPointerEvents}
                />
              </div>
            </div> */}
          </div>
          <div className="w-full flex flex-col justify-end">
            <div className="w-full rounded-[0.8rem] flex items-end overflow-hidden">
              <div className="z-[1] flex absolute w-full h-fit top-0 rounded-md pt-5 px-5">
                {/* {courseStars && (
                  <div className="flex gap-1.5 items-center justify-center h-7 relative z-[1] opacity-0 transition-600 group-hover/card:opacity-100">
                    <span
                      className="font-semibold text-left leading-[18px] text-lg mt-[3px]"
                      style={{
                        filter:
                          "drop-shadow(0 0px 0px rgb(0 0 0 / 0.3)) drop-shadow(0 0px 4px rgb(0 0 0 / 0.3))"
                      }}>
                      {courseStars < 4.7 ? "4.7" : courseStars}
                    </span>
                    <img
                      src="/images/assets/star_filled.svg"
                      alt="Filled star"
                      className="w-[18px] h-auto"
                    />
                  </div>
                )} */}
              </div>
              {isPurchaseOrRescueCourse && (
                <>
                  <div className="z-[1] pointer-events-none flex absolute w-full h-fit top-0 rounded-md justify-end pt-5 px-5">
                    <div
                      className="h-7 w-7 rounded-full flex items-center justify-center text-white transition-600"
                      style={{
                        background: newCourseHover ? "#0c321f40" : "#3a3b41cc"
                      }}>
                      <div className="flex flex-col">
                        <div
                          className={`overflow-hidden h-1 mb-[50%] transition-600 ${
                            newCourseHover
                              ? "translate-x-[4px]"
                              : "translate-x-[1px]"
                          }`}>
                          <div className="w-[6px] h-2 rounded-full border border-white"></div>
                        </div>
                        <div className="w-2 h-[6px] rounded-[1.5px] bg-white mt-[-50%]"></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {videoFirstFrame ? (
                <video
                  src={image}
                  alt="Imagem do Curso"
                  autoPlay={false}
                  muted={true}
                  style={{
                    minHeight: "auto",
                    aspectRatio: forceVerticalResolution && 9 / 16
                  }}
                  className={`object-cover w-full rounded-[0.8rem] p-1 ${
                    smallCard
                      ? "max-lg:h-[77.74vw] max-lg:w-[43.73vw]"
                      : "max-lg:h-[105.72vw] max-lg:w-[59.47vw]"
                  }`}
                />
              ) : (
                <img
                  src={image}
                  alt="Imagem do Curso"
                  style={{
                    minHeight: "auto",
                    aspectRatio: 9 / 16
                  }}
                  className="object-cover w-full rounded-[0.8rem] transition-all duration-[2500ms] ease-out group-hover/card:scale-110 group-hover/card:duration-[5000ms]"
                />
              )}
            </div>
            <div className="w-full opacity-100 px-3 pt-3 flex flex-col gap-1">
              <p className="w-full text-sm select-none leading-tight font-semibold text-themeColor max-lg:text-[0.75rem]">
                {typeCourse}
              </p>
              <p className="max-lg:leading-tight max-lg:text-sm max-lg:text-ellipsis max-lg:overflow-hidden">
                {name}
              </p>
              {teacher && (
                <p className="w-full text-xs text-textSecondary select-none leading-tight font-normal">
                  Ministrado por {teacher}
                </p>
              )}
            </div>
          </div>
        </WrapperComponent>
      </section>
    </div>
  );
}

export default OriginalCoursesCard;
