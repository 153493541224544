import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Blockquote from "../../components/Blockquote/Blockquote";
import InfoCard from "../../components/Infocard/Infocard";
import StepsList from "../../components/StepsList/StepsList";
import {
  HiMiniDocumentDuplicate,
  HiBanknotes,
  HiRectangleStack,
  HiMiniMapPin,
  HiAcademicCap,
  HiExclamationTriangle,
  HiInformationCircle,
  HiUserCircle,
  HiIdentification,
  HiMapPin,
  HiMiniArrowDownTray,
  HiMiniGlobeAlt,
  HiDocument,
  HiMiniCalendar,
  HiClipboardDocumentList,
  HiEye,
  HiMiniShare
} from "react-icons/hi2";

import { getListStudentCourses } from "../../services/courseService/course.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import Cookies from "js-cookie";
import { Toastify } from "../../components/Toastify/Toastify";
import SelectElement from "../../components/SelectElement/MainSelect";
import {
  getStepsIssueCertificate,
  getUpdateStudentPortal,
  getIssueCertificate,
  postPaymentCertificate,
  deleteCancelIssueCertificate
} from "../../services/certificateService/certificate.service";
import moment from "moment";
import Input from "../../components/Input/input";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import CertFaculdadeLibanoFront from "../../components/CertificatePreview/CertFaculdadeLibanoFront";
import CertSoftskillsFront from "../../components/CertificatePreview/CertSoftskillsFront.jsx";
import CertFaculdadeLibanoBack from "../../components/CertificatePreview/CertFaculdadeLibanoBack";
import { Link } from "react-router-dom";
import SelectButton from "../../components/SelectButton/SelectButton";
import SelectButtonFinance from "../../components/SelectButtonFinance/SelectButtonFinance";
import { downloadDeclarations } from "../../services/declarationsService/declarations.service";
import axios from "axios";
import { viaCep } from "../../services/viaCep/viaCep.service";
import { barCodeImg } from "../../services/barCodeService/barCode.service";
import CreditCardForm from "../../components/CreditCardComponents/CreditCardForm";
import { RiWhatsappFill } from "react-icons/ri";
import CertCelsoLisboaFront from "../../components/CertificatePreview/CertCelsoLisboaFront";
import CertCelsoLisboaBack from "../../components/CertificatePreview/CertCelsoLisboaBack";
import { nationalitysSelect } from "../../services/selectService/nationalitys";
import { brazilianStatesSelect } from "../../services/selectService/brazilianStates";
import { ufSelect } from "../../services/selectService/uf";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import "moment/locale/pt-br";
import { certifier } from "../../mocks/certifier";
import CertCursosLivresFront from "../../components/CertificatePreview/CertCursosLivresFront.jsx";
import CertCursosLivresBack from "../../components/CertificatePreview/CertCursosLivresBack.jsx";
import { copyText } from "../../services/copyText/copyText.jsx";
import CertJoelJotaFront from "../../components/CertificatePreview/CertJoelJotaFront.jsx";

function CertificatePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isStepNumber, setIsStepNumber] = useState(0);
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [selectedCourseOption, setSelectedCourseOption] = useState({});
  const [selectedModuleOption, setSelectedModuleOption] = useState({});
  const [certificateContent, setCertificateContent] = useState(undefined);
  const [certificateType, setCertificateType] = useState("digital");
  const [waitSteps, setWaitSteps] = useState(undefined);
  const [steps, setSteps] = useState(undefined);
  const [wrongZipCode, setWrongZipCode] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("creditCard");
  const [mobileScreen, setMobileScreen] = useState(1);
  const [transit, setTransit] = useState(null);
  const [delivered, setDelivered] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);
  const [financeBankSlipData, setfinanceBankSlipData] = useState(null);

  function isEighteenOrOlder(birthDate) {
    const birthDateObject = new Date(birthDate);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return birthDateObject <= eighteenYearsAgo;
  }

  const valueCertificate = 127.5;
  const [basicInfosForm, setBasicInfosForm] = useState({
    name: "",
    nationality: "",
    rg: "",
    organIssuing: "",
    uf: "",
    birthDate: "",
    birthState: "",
    othersBirthState: ""
  });

  const [addressInfosForm, setAddressInfosForm] = useState({
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: ""
  });

  const Nationalitys = nationalitysSelect;

  const UFs = ufSelect;

  const formatUf = (uf) => {
    const brazilianStates = brazilianStatesSelect;

    const state = brazilianStates.find((state) => state.uf === uf);
    return state ? state.name : "";
  };

  const UFsWithOthersOption = () => {
    let allUpdatedUFS = [];
    // UFs.map((UF) => {
    //   allUpdatedUFS.push({
    //     fromForm: "birthState",
    //     value: UF.value,
    //     label: UF.label,
    //   });
    // });
    UFs.forEach((UF) => {
      allUpdatedUFS.push({
        fromForm: "birthState",
        value: UF.value,
        label: UF.label
      });
    });
    allUpdatedUFS.push({
      fromForm: "birthState",
      value: "Outros",
      label: "Outros"
    });
    return allUpdatedUFS;
  };

  const handleSetMobileScreen = () => {
    setMobileScreen(2);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchStudentCourses() {
      setIsLoading(true);
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        setStudentInfos(student);
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId
        });

        if (coursesResponse.status === 200) {
          const allCourses = coursesResponse.data
            // .filter((course) => course.areaName !== "Softskills")
            .map((course) => ({
              label: course.courseName,
              value: course.courseAlias,
              courseId: course.courseId,
              matriculationId: course.matriculationId,
              courseDisciplines: course.courseDisciplines,
              typeCourse: course.typeCourse,
              certifier: course.certifier,
              areaName: course.areaName
            }));

          setCoursesOptions(allCourses);
          if (allCourses.length > 0) {
            const lastCourseAccessed = Cookies.get(
              "lastCourseAccessed_certificate"
            );
            let courseToSelect = allCourses[0];

            if (lastCourseAccessed) {
              const foundCourse = allCourses.find(
                (course) => course.value === lastCourseAccessed
              );
              if (foundCourse) {
                courseToSelect = foundCourse;
              }
            }
            setSelectedCourseOption(courseToSelect);

            const lastModuleAccessed = Cookies.get(
              "lastCourseAccessed_certificateModule"
            );
            let moduleToSelect = courseToSelect.courseDisciplines[0];

            if (lastModuleAccessed) {
              const foundModule = courseToSelect.courseDisciplines.find(
                (mod) => mod._id === lastModuleAccessed
              );
              if (foundModule) {
                moduleToSelect = foundModule;
              }
            }
            const formattedModule = {
              value: moduleToSelect._id,
              label: moduleToSelect.name,
              workload: moduleToSelect.workload,
              typeCourse: "Módulos"
            };
            setSelectedModuleOption(formattedModule);
          }
        } else {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
      } finally {
        setIsLoading(false);
      }
    }
    fetchStudentCourses();
  }, []);

  async function callHandleCertificate() {
    if (selectedCourseOption && studentInfos) {
      setIsLoading(true);
      try {
        console.log(`selectedModuleOption`, selectedModuleOption);
        const response = await getStepsIssueCertificate({
          studentInfos,
          courseId: selectedCourseOption.courseId,
          matriculationId: selectedCourseOption.matriculationId,
          disciplineId: selectedModuleOption.value
        });
        if (response.status !== 200) {
          Toastify("error", "Erro ao buscar etapas de emissão do certificado.");
          return;
        }
        setCertificateContent(response.data);
        if (response.data.payment.data !== null) {
          setMobileScreen(2);
          setIsStepNumber(4);
          handleStartAddressAutocomplete(response.data);
        } else if (response.data.issue.data !== null) {
          setBasicInfosForm({
            name: response.data.issue.data.student.student.name || "",
            nationality: response.data.issue.data.student.student.filiation
              .nationality
              ? response.data.issue.data.student.student.filiation
                  .nationality === "Brasileiro"
                ? "Brasileira"
                : response.data.issue.data.student.student.filiation.nationality
              : "",
            rg: response.data.issue.data.student.student.rg || "",
            organIssuing:
              response.data.issue.data.student.student.organIssuing || "",
            uf: response.data.issue.data.student.student.uf || "",
            birthDate:
              String(
                String(
                  response.data.issue.data.student.student.filiation.birthDate
                ).split("T")[0]
              ) === "undefined"
                ? ""
                : String(
                    response.data.issue.data.student.student.filiation.birthDate
                  ).split("T")[0],
            birthState: UFs.find(
              (UF) =>
                UF.value ===
                response.data.issue.data.student.student.filiation.homeState
            )
              ? UFs.find(
                  (UF) =>
                    UF.value ===
                    response.data.issue.data.student.student.filiation.homeState
                ).value
              : "Outros",
            othersBirthState: UFs.find(
              (UF) =>
                UF.value ===
                response.data.issue.data.student.student.filiation.homeState
            )
              ? ""
              : response.data.issue.data.student.student.filiation.homeState
          });
        } else if (response.data.accompanyCertificate.data !== null) {
          let certificate = response.data.accompanyCertificate.data;

          let dataSituation = {
            requested: null,
            doing: null,
            transit: null,
            delivered: null
          };

          certificate.situation.forEach((situation) => {
            if (situation.status) {
              if (situation.status === "requested") {
                dataSituation.requested = situation;
              } else if (situation.status === "doing") {
                dataSituation.doing = situation;
              } else if (situation.status === "transit") {
                dataSituation.transit = situation;
                setTransit(situation);
              } else if (situation.status === "delivered") {
                dataSituation.delivered = situation;
                setDelivered(situation);
              }
            }
          });

          let responseSteps = [];

          responseSteps.push({
            content: (
              <div className="flex flex-col gap-1.5 justify-center">
                <span className="font-semibold text-sm text-stepsIconAndText">
                  Solicitado
                </span>
                <span className="text-xs text-textSecondary">
                  {moment(dataSituation.requested.date).format(
                    "DD/MM/YYYY [às] HH:mm"
                  )}
                </span>
              </div>
            ),
            activated: true,
            icon: <HiClipboardDocumentList />
          });

          responseSteps.push({
            content: (
              <div className="flex flex-col gap-1.5 justify-center">
                <span className="font-semibold text-sm text-stepsIconAndText">
                  Em confecção
                </span>
                <span className="text-xs text-textSecondary">
                  {dataSituation.doing === null
                    ? moment(dataSituation.requested.date)
                        .add(10, "days")
                        .format("[Previsto para ]DD [de] MMMM [de] YYYY")
                    : dataSituation.doing !== null
                    ? moment(dataSituation.doing.date).format(
                        "DD/MM/YYYY [às] HH:mm"
                      )
                    : ""}
                </span>
              </div>
            ),
            activated: dataSituation.doing ? true : false,
            icon: <HiRectangleStack />
          });

          if (certificate.type === "material") {
            responseSteps.push({
              content: (
                <div className="flex flex-col gap-1.5 justify-center">
                  <span className="font-semibold text-sm text-stepsIconAndText">
                    Em trânsito
                  </span>
                  <span className="text-xs text-textSecondary">
                    {dataSituation.doing === null
                      ? null
                      : dataSituation.transit === null
                      ? moment(dataSituation.doing.date)
                          .add(45, "days")
                          .format("[Previsto para ]DD [de] MMMM [de] YYYY")
                      : moment(dataSituation.transit.date).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )}
                  </span>
                </div>
              ),
              activated: dataSituation.transit ? true : false,
              icon: <HiClipboardDocumentList />
            });
          }

          responseSteps.push({
            content: (
              <div className="flex flex-col gap-1.5 justify-center">
                <span className="font-semibold text-sm text-stepsIconAndText">
                  Entregue
                </span>
                <span className="text-xs text-textSecondary">
                  {certificate.type === "material"
                    ? dataSituation.doing === null
                      ? null
                      : dataSituation.delivered === null
                      ? dataSituation.transit &&
                        moment(dataSituation.transit.date)
                          .add(
                            `${
                              certificate.shipping.deadline
                                ? certificate.shipping.deadline
                                : 10
                            }`,
                            "days"
                          )
                          .format("[Previsto para ]DD [de] MMMM [de] YYYY")
                      : moment(dataSituation.delivered.date).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )
                    : dataSituation.doing === null
                    ? null
                    : dataSituation.delivered === null
                    ? moment(dataSituation.doing.date)
                        .add(45, "days")
                        .format("[Previsto para ]DD [de] MMMM [de] YYYY")
                    : moment(dataSituation.delivered.date).format(
                        "DD/MM/YYYY [às] HH:mm"
                      )}
                </span>
              </div>
            ),
            activated: dataSituation.delivered ? true : false,
            icon: <HiBanknotes />
          });

          setWaitSteps(responseSteps);
          setIsStepNumber(10);
        }
      } catch (err) {
        console.error("errrr", err);
        Toastify("error", `Erro ao puxar certificado: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    setBasicInfosForm({
      name: "",
      nationality: "",
      rg: "",
      organIssuing: "",
      uf: "",
      birthDate: "",
      birthState: "",
      othersBirthState: ""
    });
    setIsStepNumber(0);
    setMobileScreen(1);
    window.scrollTo(0, 0);
    setCertificateContent(undefined);
    setCertificateType("digital");
    setWaitSteps(undefined);
    setSteps(undefined);
    // eslint-disable-next-line
  }, [selectedCourseOption, selectedModuleOption]);

  useEffect(() => {
    if (selectedCourseOption && selectedModuleOption) {
      // Chama o certificado somente quando ambos os estados estiverem definidos
      callHandleCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModuleOption, selectedCourseOption]);

  useEffect(() => {
    if (certificateContent) {
      let responseSteps = [];
      if (!certificateContent.pillars.status) {
        responseSteps.push({
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm text-stepsIconAndText">
                Período do curso
              </span>
              <span className="text-xs text-textSecondary">
                Tempo mínimo de{" "}
                {certificateContent.pillars.data.minTime.minTimeCourse}{" "}
                {certificateContent.pillars.data.minTime.minTimeCourse > 1
                  ? "meses"
                  : "mês"}
                .
              </span>
            </div>
          ),
          activated: certificateContent.pillars.data.minTime.status,
          icon: <HiClipboardDocumentList />
        });
        responseSteps.push({
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm text-stepsIconAndText">
                Documentação
              </span>
              <span className="text-xs text-textSecondary">
                Contrato{" "}
                {selectedCourseOption.areaName !== "Softskills"
                  ? "e documentos pessoais."
                  : ""}
              </span>
            </div>
          ),
          activated: certificateContent.pillars.data.documents.status,
          icon: <HiRectangleStack />
        });
        selectedCourseOption.areaName !== "Softskills" &&
          responseSteps.push({
            content: (
              <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-sm text-stepsIconAndText">
                  Avaliações
                </span>
                <span className="text-xs text-textSecondary">
                  Provas e TCC.
                </span>
              </div>
            ),
            activated: certificateContent.pillars.data.avaliation.status,
            icon: <HiAcademicCap />
          });
        selectedCourseOption.areaName !== "Softskills" &&
          responseSteps.push({
            content: (
              <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-sm text-stepsIconAndText">
                  Financeiro
                </span>
                <span className="text-xs text-textSecondary">
                  Mensalidades.
                </span>
              </div>
            ),
            activated: certificateContent.pillars.data.finance.status,
            icon: <HiBanknotes />
          });
      }

      if (certificateContent.pillars.status) {
        setSteps(undefined);
        return;
      }

      setSteps(responseSteps);
    }
    // eslint-disable-next-line
  }, [certificateContent]);

  const handleSelectCourseChange = (e) => {
    setMobileScreen(1);
    window.scrollTo(0, 0);
    const formattedModule = {
      value: e.courseDisciplines[0]._id,
      label: e.courseDisciplines[0].name,
      workload: e.courseDisciplines[0].workload,
      typeCourse: "Módulos"
    };
    setSelectedModuleOption(formattedModule);
    setSelectedCourseOption(e);
    Cookies.set("lastCourseAccessed_certificate", e.value, {
      expires: 1 / 24
    });
  };

  const handleSelectModuleChange = (e) => {
    setMobileScreen(1);
    window.scrollTo(0, 0);
    setSelectedModuleOption(e);
    Cookies.set("lastCourseAccessed_certificateModule", e.value, {
      expires: 1 / 24
    });
  };

  const paymentBankSlip = async () => {
    let financeBankSlip = await postPaymentCertificate({
      studentInfos,
      data: {
        selectId: certificateContent.payment.data.financeId,
        value: valueCertificate * 100
      },
      type: "bankSlip"
    });
    if (financeBankSlip.status === 200) {
      if (financeBankSlip.data) {
        financeBankSlip.data.paymentPlan.paymentMethods.forEach((method) => {
          if (method.alias === "Boleto") {
            setfinanceBankSlipData(method);
          }
        });
      }
    } else {
      Toastify("error", "Erro ao gerar boleto.");
    }
  };

  const handleSelectMethod = async (method) => {
    setSelectedMethod(method);
    if (method === "bankSlip") {
      setIsLoading(true);
      await paymentBankSlip();
      setIsLoading(false);
    }
  };

  function totalDoc(data) {
    if (certificateContent.pillars.data.minTime.areaAlias === "softskills")
      return 1;
    let total = 0;
    for (let doc in data) {
      if (doc !== "status") {
        if (doc === "contract") {
          if (data[doc] === false) {
            total++;
          }
        } else {
          if (data[doc] !== "approved") {
            total++;
          }
        }
      }
    }
    return total;
  }

  function timeLineDocuments(data) {
    let timeLine = [];

    timeLine.push({
      color: data["contract"] === false ? "#808080" : "#483d8b",
      text: `Contrato`
    });
    selectedCourseOption.areaName !== "Softskills" &&
      timeLine.push({
        color:
          data["identification"] !== "approved"
            ? data["identification"] === "notSend"
              ? "#808080"
              : "#fde047"
            : "#483d8b",
        text: `CNH ou RG`
      });
    selectedCourseOption.areaName !== "Softskills" &&
      timeLine.push({
        color:
          data["graduation"] !== "approved"
            ? data["graduation"] === "notSend"
              ? "#808080"
              : "#fde047"
            : "#483d8b",
        text: `Diploma de Graduação`
      });
    selectedCourseOption.areaName !== "Softskills" &&
      timeLine.push({
        color:
          data["address"] !== "approved"
            ? data["address"] === "notSend"
              ? "#808080"
              : "#fde047"
            : "#483d8b",
        text: `Comprovante de Endereço`
      });

    return timeLine;
  }

  function timeLineAvaliations(data) {
    let timeLine = [];

    timeLine.push({
      color: "#483d8b",
      text: `Aprovado em ${data["avaliationsApproved"]} avaliação(ões)`
    });

    timeLine.push({
      color: "#ff0000",
      text: `Reprovado em ${data["avaliationsReproved"]} avaliação(ões)`
    });

    timeLine.push({
      color: data["avaliationAvf"] === true ? "#483d8b" : "#808080",
      text:
        data["avaliationAvf"] === true
          ? "Realizou a avaliação final "
          : "Não realizou a avaliação final"
    });

    if (data["tccDispense"] === true) {
      timeLine.push({
        color: "#483d8b",
        text: "TCC Dispensado"
      });
    } else {
      timeLine.push({
        color: data["tcc"] === true ? "#483d8b" : "#808080",
        text: data["tcc"] === true ? "Realizou o TCC" : "Não realizou o TCC"
      });
    }

    return timeLine;
  }

  const handleBasicInfosChange = (e) => {
    let name, value;
    if (e.hasOwnProperty("value") && !e.target) {
      name = e.fromForm;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "rg") {
      setBasicInfosForm({
        ...basicInfosForm,
        [name]: value.trim().toUpperCase()
      });
    } else if (name === "organIssuing") {
      setBasicInfosForm({
        ...basicInfosForm,
        [name]: value.toUpperCase().trimStart().replace(/\d+/g, "")
      });
    } else if (name === "name") {
      let formattedValue = value
        .trimStart()
        .replace(/[^a-zA-Z\s]/g, "")
        .replace(/\s+/g, " ")
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

      setBasicInfosForm({
        ...basicInfosForm,
        [name]: formattedValue
      });
    } else {
      setBasicInfosForm({ ...basicInfosForm, [name]: value });
    }
  };

  const handleUpdateStudentInfos = async () => {
    setIsLoading(true);
    try {
      let student = certificateContent.issue.data.student;

      student.student.name = basicInfosForm.name.trim();
      student.student.filiation.nationality =
        basicInfosForm.nationality === "Brasileira"
          ? "Brasileiro"
          : basicInfosForm.nationality;
      student.student.rg = basicInfosForm.rg.trim();
      student.student.organIssuing = basicInfosForm.organIssuing.trim();
      student.student.uf = basicInfosForm.uf;

      student.student.filiation.homeState =
        basicInfosForm.birthState === "Outros"
          ? basicInfosForm.othersBirthState.trim()
          : basicInfosForm.birthState;

      const formattedBirthDate = moment
        .utc(basicInfosForm.birthDate)
        .add(3, "hours")
        .format();

      student.student.filiation.birthDate = formattedBirthDate;

      const response = await getUpdateStudentPortal({
        student,
        studentInfos
      });
      if (response.status === 200) {
        if (selectedCourseOption.areaName === "Softskills") {
          handleRequestCertificate();
          return;
        } else {
          setIsStepNumber(1);
        }
        window.scrollTo(0, 0);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(`error`, error);
      Toastify("error", "Erro generalizado ao enviar informações do aluno.");
      setIsLoading(false);
    }
  };

  const handleShareLinkedIn = async () => {
    if (selectedCourseOption.typeCourse === "Pós-Graduação") {
      // Formação Acadêmica
      const baseUrl =
        "https://www.linkedin.com/in/me/edit/forms/education/new/";
      window.open(baseUrl, "_blank");
    } else {
      // Certificados
      const baseUrl = "https://www.linkedin.com/profile/add?";
      const startTask = "CERTIFICATION_NAME";
      const name = selectedCourseOption.label;
      const organizationId = "77004361";
      const issueYear = moment().format("YYYY");
      const issueMonth = moment().format("MM");
      const certUrl = encodeURI(
        certificateContent.accompanyCertificate.data.metaData.certificateURL
      );
      const certId = certificateContent.accompanyCertificate.data._id;
      const linkedInUrl = encodeURI(
        `${baseUrl}startTask=${startTask}&name=${name}&organizationId=${organizationId}&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${certUrl}&certId=${certId}`
      );
      window.open(linkedInUrl, "_blank");
    }
  };

  const handleRequestCertificate = async (manualCertificate) => {
    setIsLoading(true);
    try {
      let data = {};
      // Alteração pertecente à atualização de integração ao Joel Jota
      data.disciplineId =
        selectedCourseOption.courseId === "67acee9b22a8d5001205a121"
          ? selectedModuleOption.value
          : undefined;

      if (certificateType === "digital" && manualCertificate !== "material") {
        data.shipping = {};
        data.address = {};
        data.typeCertificate = "digital";
      } else {
        data.address = addressInfosForm;
        data.typeCertificate = "material";
      }

      data.matriculationId = selectedCourseOption.matriculationId;
      data.courseId = selectedCourseOption.courseId;
      setIsLoading(true);
      const response = await getIssueCertificate({
        studentInfos,
        data
      });
      setIsLoading(true);
      window.location.reload();
      if (!response.status === 200) {
        Toastify("error", "Erro ao solicitar o certificado.");
        setIsLoading(false);
      }
    } catch (error) {
      Toastify("error", "Erro generalizado ao solicitar o certificado.");
      setIsLoading(false);
    }
  };

  const handleDownloadDeclaration = async () => {
    downloadDeclaration({
      matriculationId: selectedCourseOption.matriculationId,
      courseId: selectedCourseOption.courseId,
      type: "declarationConclusion",
      name: `Declaração de Conclusão - ${selectedCourseOption.label} - ${certifier.generalSettings.shortName}`,
      sessionId: studentInfos.sessionId
    });
  };

  const downloadDeclaration = async (data) => {
    setIsLoading(true);
    try {
      let response = await downloadDeclarations(data);
      if (response.status === 200) {
        const tempLink = document.createElement("a");
        tempLink.href = URL.createObjectURL(response.data);
        tempLink.setAttribute("download", `${String(data.name)}.pdf`);
        tempLink.click();
        Toastify("success", "Declaração baixada com sucesso.");
      } else {
        console.error("Erro ao baixar o contrato");
        Toastify(
          "error",
          "Erro ao baixar o conteúdo. Tente novamente mais tarde."
        );
      }
    } catch (error) {
      console.error("Erro ao baixar o contrato", error);
      Toastify(
        "error",
        "Erro ao baixar o conteúdo. Tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadCertificate = async () => {
    setIsLoading(true);
    try {
      console.log(`certificateContent`, certificateContent);
      downloadCertificate({
        url: certificateContent.accompanyCertificate.data.metaData
          .certificateSrc,
        // Alteração pertecente à atualização de integração ao Joel Jota
        name: `Certificado - ${selectedCourseOption.label} - ${
          selectedCourseOption.courseId === "67acee9b22a8d5001205a121"
            ? selectedModuleOption.label
            : certifier.generalSettings.shortName
        }`
      });
    } catch (error) {
      Toastify(
        "error",
        "Erro ao baixar o certificado. Tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCancelIssueCertificate = async (financeId) => {
    setIsLoading(true);
    try {
      const response = await deleteCancelIssueCertificate({
        studentInfos,
        financeId: financeId
      });
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      Toastify("error", "Erro ao cancelar solicitação de certificado.");
    }
  };

  const downloadCertificate = async (data) => {
    try {
      const url = data.url;
      const options = {
        responseType: "blob"
      };
      console.log(`url`, data);
      const response = await axios.get(url, options);
      const tempLink = document.createElement("a");
      tempLink.href = URL.createObjectURL(response.data);
      tempLink.setAttribute("download", `${String(data.name)}.pdf`);
      tempLink.click();
      Toastify("success", "Certificado baixado com sucesso.");
    } catch (error) {
      console.error("Erro ao baixar o conteúdo", error);
      Toastify(
        "error",
        "Erro ao baixar o conteúdo. Tente novamente mais tarde."
      );
    }
  };

  const handleAddressChange = async (e) => {
    let name, value;
    if (e.hasOwnProperty("value") && !e.target) {
      name = "state";
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "zipCode") {
      setAddressInfosForm({
        ...addressInfosForm,
        [name]: (() => {
          let cleanedValue = value
            .trim()
            .replace(/[^0-9]/g, "")
            .slice(0, 8);

          if (cleanedValue.length >= 6) {
            cleanedValue = `${cleanedValue.slice(0, 5)}-${cleanedValue.slice(
              5
            )}`;
          }

          if (cleanedValue.endsWith("-")) {
            cleanedValue = cleanedValue.slice(0, -1);
          }

          return cleanedValue;
        })()
      });
      if (value.length === 9) {
        setIsLoading(true);
        try {
          const cep = value.replace("-", "");
          const response = await viaCep(cep);
          if (response.data.erro) {
            Toastify("error", "CEP não encontrado.");
            setWrongZipCode(true);
            return;
          }
          if (response.status === 200) {
            setWrongZipCode(false);
            setAddressInfosForm({
              ...addressInfosForm,
              [name]: value,
              street: response.data.logradouro,
              district: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf
            });
          }
        } catch (error) {
          console.error("Erro ao buscar o CEP", error);
          Toastify(
            "error",
            "Erro ao buscar o CEP. Tente novamente mais tarde."
          );
          setWrongZipCode(true);
        } finally {
          setIsLoading(false);
        }
      }
    } else if (name === "state") {
      setAddressInfosForm({
        ...addressInfosForm,
        [name]: value
      });
    } else {
      let formattedValue = value
        .trimStart()
        .replace(/\s+/g, " ")
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

      setAddressInfosForm({
        ...addressInfosForm,
        [name]: formattedValue
      });
    }
  };

  const handleStartAddressAutocomplete = (content) => {
    let address;
    if (content) {
      address = content.payment.data.address;
    } else {
      address = certificateContent.issue.data.student.student.address;
    }
    const addressAutoComplete = {
      city: address.city,
      complement: address.complement || undefined,
      district: address.district,
      number: address.number,
      state: address.state,
      street: address.street,
      zipCode: address.zipCode
    };
    if (address) setAddressInfosForm(addressAutoComplete);
  };

  const handlePaymentForm = async () => {
    try {
      await handleRequestCertificate("material");
      setIsStepNumber(4);
    } catch (err) {
      console.error(err);
      Toastify("error", "Erro ao solicitar o certificado físico.");
    }
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen max-mc:h-full">
      <HeaderBar
        courseNavigation={false}
        actualPage="Documentos"
        mobileMainText="Certificado"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Documentos" />
      <LoadingSection isLoading={isLoading} />
      {certificateContent && (
        <section className="mt-9 mb-9 pl-0 flex w-full h-full max-w-[1170px] justify-center min-h-[600px] bg-backgroundTwo rounded-xl overflow-hidden max-mc:flex-col max-mc:my-0 max-mc:rounded-none max-mc:h-full max-mc:justify-start">
          <div
            style={{
              paddingTop: windowWidth < 960 ? navBarHeader : 0,
              paddingBottom: windowWidth < 960 ? navBar : 0
            }}
            className={`${
              windowWidth < 960 && mobileScreen === 1
                ? "h-[auto] w-full"
                : windowWidth < 960
                ? "hidden"
                : "flex w-[65%]"
            }`}>
            <div
              className={`p-32 py-16 relative min-h-[75vh] border-r-[1px] border-black/30 w-full max-mc:p-8 max-mc:px-[5vw] max-mc:flex-col max-mc:gap-y-8`}>
              <div className="flex flex-col gap-y-8 w-full h-full">
                <div>
                  {studentInfos && (
                    <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                      {String(studentInfos.name).split(" ")[0]}
                      {steps
                        ? ", vamos solicitar o seu certificado?"
                        : isStepNumber === 0 || isStepNumber === 1
                        ? ", vamos fazer a verificação do seu certificado?"
                        : isStepNumber === 2
                        ? ", como você quer receber o seu certificado?"
                        : isStepNumber === 3
                        ? ", onde você quer receber o seu certificado?"
                        : isStepNumber === 4
                        ? `, o seu certificado chegará em ${moment()
                            .add("55", "days")
                            .format("DD [de] MMMM")}.`
                        : isStepNumber === 10 && delivered === null
                        ? ", agora é só aguardar o seu certificado."
                        : ", seu certificado já está pronto!"}
                    </h1>
                  )}

                  {steps ? (
                    <span className="text-textPrimary text-sm">
                      Para solicitar o seu certificado é necessário que os
                      pilares abaixos estejam regularizados.
                    </span>
                  ) : isStepNumber === 0 ? (
                    <span className="text-textPrimary text-sm">
                      Olhe cuidadosamente cada detalhe da parte da <b>FRENTE</b>{" "}
                      do seu certificado como seus dados pessoais.
                    </span>
                  ) : isStepNumber === 1 ? (
                    <span className="text-textPrimary text-sm">
                      Olhe cuidadosamente cada detalhe do <b>VERSO</b> do seu
                      certificado como suas notas das avaliações e TCC.
                    </span>
                  ) : isStepNumber === 2 ? (
                    <span className="text-textPrimary text-sm">
                      Você pode escolher entre o modelo digital ou físico.
                    </span>
                  ) : isStepNumber === 3 ? (
                    <span className="text-textPrimary text-sm">
                      Confirme o endereço de entrega do seu certificado.
                    </span>
                  ) : (
                    isStepNumber === 4 && (
                      <span className="text-textPrimary text-sm">
                        Será enviado para {addressInfosForm.street},{" "}
                        {addressInfosForm.number}, {addressInfosForm.district},{" "}
                        {addressInfosForm.city},{" "}
                        {formatUf(addressInfosForm.state)}.
                      </span>
                    )
                  )}
                </div>
                {steps ? (
                  <StepsList steps={steps} />
                ) : isStepNumber === 0 && certificateContent.issue.data ? (
                  selectedCourseOption.certifier === "Faculdade Líbano" ? (
                    selectedCourseOption.typeCourse === "Cursos Livres" ? (
                      // Alteração pertecente à atualização de integração ao Joel Jota
                      selectedCourseOption.areaName === "Softskills" ? (
                        selectedCourseOption.courseId ===
                        "67acee9b22a8d5001205a121" ? (
                          <CertJoelJotaFront
                            formData={basicInfosForm}
                            selectedCourse={selectedCourseOption}
                            selectedModule={selectedModuleOption}
                            certificateContent={certificateContent.issue.data}
                          />
                        ) : (
                          <CertSoftskillsFront
                            formData={basicInfosForm}
                            selectedCourse={selectedCourseOption}
                            certificateContent={certificateContent.issue.data}
                          />
                        )
                      ) : (
                        <CertCursosLivresFront
                          formData={basicInfosForm}
                          selectedCourse={selectedCourseOption}
                          certificateContent={certificateContent.issue.data}
                        />
                      )
                    ) : (
                      <CertFaculdadeLibanoFront
                        formData={basicInfosForm}
                        selectedCourse={selectedCourseOption}
                        certificateContent={certificateContent.issue.data}
                      />
                    )
                  ) : selectedCourseOption.certifier === "Celso Lisboa" ? (
                    <CertCelsoLisboaFront
                      formData={basicInfosForm}
                      selectedCourse={selectedCourseOption}
                      certificateContent={certificateContent.issue.data}
                    />
                  ) : (
                    "Frente da pré-visualização da certificadora não disponível no momento."
                  )
                ) : isStepNumber === 1 && certificateContent.issue.data ? (
                  selectedCourseOption.certifier === "Faculdade Líbano" ? (
                    selectedCourseOption.typeCourse === "Cursos Livres" ? (
                      <CertCursosLivresBack
                        formData={basicInfosForm}
                        selectedCourse={selectedCourseOption}
                        certificateContent={certificateContent.issue.data}
                      />
                    ) : (
                      <CertFaculdadeLibanoBack
                        formData={basicInfosForm}
                        selectedCourse={selectedCourseOption}
                        certificateContent={certificateContent.issue.data}
                      />
                    )
                  ) : selectedCourseOption.certifier === "Celso Lisboa" ? (
                    <CertCelsoLisboaBack
                      formData={basicInfosForm}
                      selectedCourse={selectedCourseOption}
                      certificateContent={certificateContent.issue.data}
                    />
                  ) : (
                    "Verso da pré-visualização da certificadora não disponível no momento."
                  )
                ) : isStepNumber === 2 ||
                  isStepNumber === 3 ||
                  isStepNumber === 4 ? (
                  selectedCourseOption.certifier === "Faculdade Líbano" ? (
                    <div className="grid grid-cols-2 gap-2">
                      <img
                        src="/images/assets/generalFrontCertificado-Libano.webp"
                        alt="Frente do Certificado Líbano"
                      />
                      <img
                        src="/images/assets/generalBackCertificado-Libano.webp"
                        alt="Verso do Certificado Líbano"
                      />
                    </div>
                  ) : selectedCourseOption.certifier === "Celso Lisboa" ? (
                    <div className="grid grid-cols-2 gap-2">
                      <img
                        src="/images/assets/generalFrontCertificado-CelsoLisboa.webp"
                        alt="Frente do Certificado Celso"
                      />
                      <img
                        src="/images/assets/generalBackCertificado-CelsoLisboa.webp"
                        alt="Verso do Certificado Celso"
                      />
                    </div>
                  ) : (
                    "Conteúdo do certificado da certificadora não foi encontrado."
                  )
                ) : (
                  isStepNumber === 10 && <StepsList steps={waitSteps} />
                )}
              </div>
            </div>
            <div
              className={`min-[1025px]:hidden flex justify-end max-mc:py-8 max-mc:px-[5vw] ${
                mobileScreen !== 1 && "max-mc:hidden"
              }`}>
              <ButtonPrimary
                verifyOnClick={handleSetMobileScreen}
                textButton="Próximo"
                desktopWidth={150}
                mobileWidth={150}
              />
            </div>
          </div>

          <div
            style={{
              paddingTop: windowWidth < 960 ? navBarHeader : 0,
              paddingBottom: windowWidth < 960 ? navBar : 0
            }}
            className={`bg-backgroundThree ${
              windowWidth < 960 && mobileScreen === 2
                ? "h-[auto] w-full"
                : windowWidth < 960
                ? "hidden"
                : "flex w-[38%]"
            }`}>
            <div
              className={`flex p-16 bg-backgroundThree gap-y-8 flex-col h-full w-full max-mc:p-8 max-mc:px-[5vw] ${
                mobileScreen !== 2 && "max-mc:hidden"
              }`}>
              {!certificateContent.pillars.status && (
                <div>
                  <h1 className="text-3xl text-textPrimary font-bold leading-none">
                    Pilar{" "}
                    {certificateContent.pillars.data.minTime.status
                      ? certificateContent.pillars.data.documents.status
                        ? 3
                        : 2
                      : 1}{" "}
                    de{" "}
                    {selectedCourseOption.areaName !== "Softskills" ? "4" : "2"}
                  </h1>
                  <span className="text-textPrimary text-sm">
                    {certificateContent.pillars.data.minTime.status
                      ? certificateContent.pillars.data.documents.status
                        ? "Verifique avaliações e TCC."
                        : "Verifique contratos e documentações."
                      : "Verifique o período do curso."}
                  </span>
                </div>
              )}

              {certificateContent.pillars.status && (
                <div className="flex flex-col">
                  <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                    {isStepNumber === 0
                      ? "Verificação Frente"
                      : isStepNumber === 1
                      ? "Verificação Verso"
                      : isStepNumber === 2
                      ? "Tipo de certificado"
                      : isStepNumber === 3
                      ? "Certificado físico"
                      : isStepNumber === 4
                      ? "Pagamento"
                      : isStepNumber === 10 && delivered === null
                      ? "Acompanhe"
                      : "Tudo certo"}
                  </h1>
                  <span className="text-textPrimary text-sm">
                    {isStepNumber === 0
                      ? "Analise os dados da frente do seu certificado."
                      : isStepNumber === 1
                      ? "Analise os dados do verso do seu certificado."
                      : isStepNumber === 2
                      ? "Escolha o tipo do certificado."
                      : isStepNumber === 3
                      ? "Preencha as informações"
                      : isStepNumber === 4
                      ? "Referente a taxa dos correios."
                      : isStepNumber === 10 && delivered === null
                      ? "O seu certificado."
                      : "Seu certificado está pronto."}
                  </span>
                </div>
              )}
              {coursesOptions.length > 0 && (
                <div className="flex flex-col gap-y-3 w-full">
                  <label className="span-small-white">Selecione o curso</label>
                  <SelectElement
                    options={coursesOptions}
                    value={selectedCourseOption.value}
                    onChange={handleSelectCourseChange}
                  />
                </div>
              )}
              {/* Alteração pertecente à atualização de integração ao Joel Jota */}
              {selectedCourseOption?.courseId ===
                "67acee9b22a8d5001205a121" && (
                <div className="flex flex-col gap-y-3 w-full">
                  <label className="span-small-white">Selecione o módulo</label>
                  <SelectElement
                    options={selectedCourseOption.courseDisciplines.map(
                      (courseDiscipline) => {
                        return {
                          value: courseDiscipline._id,
                          label: courseDiscipline.name,
                          workload: courseDiscipline.workload,
                          typeCourse: "Módulos"
                        };
                      }
                    )}
                    value={selectedModuleOption.value}
                    onChange={handleSelectModuleChange}
                  />
                </div>
              )}
              {!certificateContent.pillars.status &&
                (!certificateContent.pillars.data.minTime.status ? (
                  <div>
                    <div className="mb-8">
                      {certificateContent.pillars.data.minTime.areaAlias !==
                      "softskills" ? (
                        <Blockquote
                          text={`Para concluir este pilar, é necessário que você complete no mínimo ${certificateContent.pillars.data.minTime.minTimeCourse} meses de período cursado.`}
                          color="#2563eb"
                          icon={<HiInformationCircle />}
                        />
                      ) : (
                        <Blockquote
                          text={`Para concluir este pilar, é necessário que você complete, no mínimo, ${certificateContent.pillars.data.minTime.minTimeCourse} mês e 75% de conclusão do curso.`}
                          color="#2563eb"
                          icon={<HiInformationCircle />}
                        />
                      )}
                    </div>
                    <InfoCard
                      typeContent="Tempo"
                      typeColor="#9c540e"
                      typeBg="#fef3c7"
                      icon={<HiExclamationTriangle />}
                      iconColor="#fde047"
                      mainContent={{
                        title:
                          certificateContent.pillars.data.minTime.timeRemain,
                        content: `É necessário completar ${
                          certificateContent.pillars.data.minTime
                            .minTimeCourse > 1
                            ? "os"
                            : ""
                        } ${
                          certificateContent.pillars.data.minTime.minTimeCourse
                        } ${
                          certificateContent.pillars.data.minTime
                            .minTimeCourse > 1
                            ? "meses"
                            : "mês"
                        } para concluir este pilar...`
                      }}
                      timelineContent={[
                        {
                          color: "#fde047",
                          text: `Iniciou o curso: ${moment(
                            certificateContent.pillars.data.minTime.startDate
                          ).format("DD/MM/YYYY")}.`
                        },
                        {
                          color: "#fde047",
                          text: `Não concluiu o curso.`
                        }
                      ]}
                      buttonText="Estudar"
                      buttonRedirect="/studentCourses"
                    />
                  </div>
                ) : certificateContent.pillars.data.minTime.status &&
                  !certificateContent.pillars.data.documents.status ? (
                  <div>
                    <div className="mb-8">
                      <Blockquote
                        text={`Para concluir este pilar, é necessário que você envie seus documentos pessoais e realize a assinatura do contrato.`}
                        color="#2563eb"
                        icon={<HiInformationCircle />}
                      />
                    </div>
                    <InfoCard
                      typeContent="Documentos"
                      typeColor="#9c540e"
                      typeBg="#fef3c7"
                      icon={<HiExclamationTriangle />}
                      iconColor="#fde047"
                      mainContent={{
                        title: `Falta${
                          totalDoc(certificateContent.pillars.data.documents) >
                          1
                            ? "m"
                            : ""
                        } ${totalDoc(
                          certificateContent.pillars.data.documents
                        )} documento${
                          totalDoc(certificateContent.pillars.data.documents) >
                          1
                            ? "s"
                            : ""
                        }.`,
                        content:
                          certificateContent.pillars.data.minTime.areaAlias ===
                          "softskills"
                            ? "É necessário assinar o contrato para concluir esse pilar."
                            : `${
                                totalDoc(
                                  certificateContent.pillars.data.documents
                                ) > 1
                                  ? "É necessário enviar todos os documentos para concluir esse pilar."
                                  : "É necessário enviar o documento restante para concluir esse pilar."
                              }`
                      }}
                      timelineContent={timeLineDocuments(
                        certificateContent.pillars.data.documents
                      )}
                      buttonText="Regularizar"
                      buttonRedirect={
                        certificateContent.pillars.data.documents[
                          "identification"
                        ] === "approved" &&
                        certificateContent.pillars.data.documents[
                          "graduation"
                        ] === "approved" &&
                        certificateContent.pillars.data.documents["address"] ===
                          "approved"
                          ? "/user/contracts"
                          : "/user/documents"
                      }
                    />
                  </div>
                ) : certificateContent.pillars.data.minTime.status &&
                  certificateContent.pillars.data.documents.status &&
                  !certificateContent.pillars.data.avaliation.status ? (
                  <div>
                    <div className="mb-8">
                      <Blockquote
                        text={`Para concluir este pilar, é necessário que você seja aprovado em todas as avaliações e no TCC, caso não tenha optado pela dispensa.`}
                        color="#2563eb"
                        icon={<HiInformationCircle />}
                      />
                    </div>
                    <InfoCard
                      typeContent="Avaliações"
                      typeColor="#9c540e"
                      typeBg="#fef3c7"
                      icon={<HiExclamationTriangle />}
                      iconColor="#fde047"
                      mainContent={{
                        title:
                          certificateContent.pillars.data.avaliation[
                            "avaliationsNotSend"
                          ] +
                            certificateContent.pillars.data.avaliation[
                              "avaliationsReproved"
                            ] +
                            (certificateContent.pillars.data.avaliation[
                              "avaliationAvf"
                            ]
                              ? 0
                              : 1) ===
                          0
                            ? `Falta o TCC`
                            : `Faltam ${
                                certificateContent.pillars.data.avaliation[
                                  "avaliationsNotSend"
                                ] +
                                (certificateContent.pillars.data.avaliation
                                  .avaliationAvf
                                  ? 0
                                  : 1)
                              } avaliação(ões)`,
                        content: `É necessário ser aprovado em todas as provas para concluir este pilar.`
                      }}
                      timelineContent={timeLineAvaliations(
                        certificateContent.pillars.data.avaliation
                      )}
                      buttonText="Regularizar"
                      buttonRedirect={
                        certificateContent.pillars.data.avaliation[
                          "avaliationsNotSend"
                        ] +
                          certificateContent.pillars.data.avaliation[
                            "avaliationsReproved"
                          ] +
                          (certificateContent.pillars.data.avaliation[
                            "avaliationAvf"
                          ]
                            ? 0
                            : 1) ===
                        0
                          ? "/tcc"
                          : "/avaliations"
                      }
                    />
                  </div>
                ) : (
                  certificateContent.pillars.data.minTime.status &&
                  certificateContent.pillars.data.documents.status &&
                  certificateContent.pillars.data.avaliation.status &&
                  !certificateContent.pillars.data.finance.status && (
                    <div>
                      <div className="mb-8">
                        <Blockquote
                          text={`Para concluir este pilar, é necessário que você complete no
                  mínimo ${certificateContent.pillars.data.minTime.minTimeCourse} meses de período cursado.`}
                          color="#2563eb"
                          icon={<HiInformationCircle />}
                        />
                      </div>
                      <InfoCard
                        typeContent="Tempo"
                        typeColor="#9c540e"
                        typeBg="#fef3c7"
                        icon={<HiExclamationTriangle />}
                        iconColor="#fde047"
                        mainContent={{
                          title:
                            certificateContent.pillars.data.minTime.timeRemain,
                          content: `É necessario realizar todos os pagamentos para concluir este pilar.`
                        }}
                        timelineContent={[
                          {
                            color: "#fde047",
                            text: `Iniciou o curso: ${moment(
                              certificateContent.pillars.data.minTime.startDate
                            ).format("DD/MM/YYYY")}.`
                          }
                        ]}
                        buttonText="Estudar"
                        buttonRedirect="/studentCourses"
                      />
                    </div>
                  )
                ))}

              {certificateContent.pillars.status && isStepNumber !== 4 && (
                <>
                  <div>
                    {isStepNumber === 0 || isStepNumber === 1 ? (
                      <Blockquote
                        text={`Ao aprovar os dados, você se responsabiliza pela emissão do certificado e pela veracidade das informações que constam no certificado.`}
                        color="#2563eb"
                        icon={<HiInformationCircle />}
                      />
                    ) : isStepNumber === 2 ? (
                      <Blockquote
                        text={
                          certificateType === "digital"
                            ? `O certificado digital estará disponível em até 45 dias após a solicitação.`
                            : `No certificado físico é necessário informar o endereço de entrega e realizar o pagamento da taxa de envio por correios.`
                        }
                        color="#2563eb"
                        icon={<HiInformationCircle />}
                      />
                    ) : isStepNumber === 3 ? (
                      <Blockquote
                        text={
                          "No certificado físico é necessário informar o endereço de entrega e realizar o pagamento da taxa de envio por correios."
                        }
                        color="#2563eb"
                        icon={<HiInformationCircle />}
                      />
                    ) : (
                      isStepNumber === 10 && (
                        <Blockquote
                          text={
                            delivered === null
                              ? "Caso precise de um documento de comprovação de conclusão até que o seu certificado chegue, você pode solicitar a declaração de conclusão."
                              : "O seu certificado já foi entregue, sempre que precisar você pode baixar a cópia escaneada."
                          }
                          color="#2563eb"
                          icon={<HiInformationCircle />}
                        />
                      )
                    )}
                  </div>
                  {isStepNumber === 0 ? (
                    <div className="mb-8 flex flex-col gap-y-8">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Nome Completo *
                        </label>
                        <Input
                          type="text"
                          name="name"
                          value={basicInfosForm.name}
                          onChange={handleBasicInfosChange}
                          optionalIcon={<HiUserCircle />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full h-full">
                        <label className="span-small-white">
                          Nacionalidade *
                        </label>
                        <SelectElement
                          options={Nationalitys}
                          disableGroups
                          value={basicInfosForm.nationality}
                          onChange={handleBasicInfosChange}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">RG *</label>
                        <Input
                          type="text"
                          name="rg"
                          value={basicInfosForm.rg}
                          onChange={handleBasicInfosChange}
                          optionalIcon={<HiIdentification />}
                        />
                      </div>
                      <div className="flex gap-x-4 w-full">
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">
                            Órg. Expedidor *
                          </label>
                          <Input
                            type="text"
                            name="organIssuing"
                            value={basicInfosForm.organIssuing}
                            onChange={handleBasicInfosChange}
                            optionalIcon={<HiIdentification />}
                          />
                        </div>
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">UF *</label>
                          <SelectElement
                            options={UFs}
                            disableGroups
                            value={basicInfosForm.uf}
                            onChange={handleBasicInfosChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Data de Nascimento *
                        </label>
                        <Input
                          type="date"
                          name="birthDate"
                          value={basicInfosForm.birthDate}
                          onChange={handleBasicInfosChange}
                          optionalIcon={<HiMiniCalendar />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Estado Natal *
                        </label>
                        <SelectElement
                          name="birthState"
                          options={UFsWithOthersOption()}
                          disableGroups
                          value={basicInfosForm.birthState}
                          onChange={handleBasicInfosChange}
                        />
                      </div>
                      {basicInfosForm.birthState === "Outros" && (
                        <div className="flex flex-col gap-y-3 w-full">
                          <label className="span-small-white">
                            Estado Natal
                          </label>
                          <Input
                            type="text"
                            name="othersBirthState"
                            value={basicInfosForm.othersBirthState}
                            onChange={handleBasicInfosChange}
                            optionalIcon={<HiMapPin />}
                          />
                        </div>
                      )}
                      <div className="flex justify-end mt-4 gap-x-6 w-full max-mc:pb-8">
                        <ButtonPrimary
                          textButton="Aprovar"
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth={150}
                          disabled={
                            !(
                              basicInfosForm.name !== "" &&
                              basicInfosForm.nationality !== "" &&
                              basicInfosForm.rg !== "" &&
                              basicInfosForm.organIssuing !== "" &&
                              basicInfosForm.uf !== "" &&
                              basicInfosForm.birthDate !== "" &&
                              isEighteenOrOlder(basicInfosForm.birthDate) &&
                              basicInfosForm.birthState &&
                              ((basicInfosForm.birthState !== "" &&
                                basicInfosForm.birthState !== "Outros") ||
                                (basicInfosForm.birthState === "Outros" &&
                                  basicInfosForm.othersBirthState &&
                                  basicInfosForm.othersBirthState !== ""))
                            )
                          }
                          verifyOnClick={handleUpdateStudentInfos}
                        />
                      </div>
                    </div>
                  ) : isStepNumber === 1 ? (
                    <div className="flex flex-col gap-y-8">
                      <div className="certificate-grade-table">
                        <table>
                          <thead>
                            <tr>
                              <th className="p-3 py-4 text-left bg-backgroundTwo">
                                Avaliação
                              </th>
                              <th className="p-3 py-4 bg-backgroundTwo">
                                Nota
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {certificateContent.issue.data.table.disciplines.map(
                              (discipline, index) => (
                                <tr key={index}>
                                  <td className="p-3 py-4 max-w-[204px]">
                                    <p className="text-left line-clamp-1">
                                      {discipline.disciplineName}
                                    </p>
                                  </td>
                                  <td className="p-3 py-4">
                                    {discipline.grade}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="flex justify-between gap-x-6 w-full max-mc:pb-8">
                        <Link
                          to={`https://api.whatsapp.com/send?phone=5511916137764&text=Ol%C3%A1%2C%20me%20chamo%20${
                            studentInfos.name && studentInfos.name !== ""
                              ? studentInfos.name
                              : "(nome desconhecido)"
                          }%20e%20gostaria%20de%20informar%20que%20h%C3%A1%20um%20erro%20nas%20minhas%20notas%20do%20certificado.%20Ap%C3%B3s%20uma%20revis%C3%A3o%20cuidadosa%2C%20percebi%20que%20algumas%20das%20minhas%20notas%20n%C3%A3o%20foram%20registradas%20corretamente.%20Pe%C3%A7o%20encarecidamente%20que%20verifiquem%20a%20situa%C3%A7%C3%A3o.`}
                          target="_blank"
                          style={{
                            width: "calc(100% - 12px)"
                          }}>
                          <ButtonPrimary
                            disableHorizontalMovement
                            optionalHoverBackground="#FFFFFF08"
                            optionalBackground="#00000000"
                            textButton="Contestar"
                            optionalTextColor={
                              certifier.colors.text.textPrimary
                            }
                            desktopWidth="100%"
                            mobileWidth="100%"
                          />
                        </Link>
                        <ButtonPrimary
                          textButton="Aprovar"
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth={150}
                          verifyOnClick={() => {
                            setIsStepNumber(2);
                          }}
                        />
                      </div>
                    </div>
                  ) : isStepNumber === 2 ? (
                    <div className="flex flex-col gap-y-8">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Selecione o tipo de certificado
                        </label>
                        <div className="flex gap-x-4 justify-between">
                          <SelectButton
                            text="Digital"
                            value="digital"
                            icon={<HiMiniGlobeAlt />}
                            onClick={() => {
                              setCertificateType("digital");
                            }}
                            selectedMethod={certificateType}
                            enableBorder
                          />
                          <SelectButton
                            text="Físico"
                            value="material"
                            icon={<HiDocument />}
                            onClick={() => {
                              setCertificateType("material");
                            }}
                            selectedMethod={certificateType}
                            enableBorder
                          />
                        </div>
                      </div>
                      <div className="flex justify-end gap-x-6 w-full">
                        <ButtonPrimary
                          textButton="Solicitar"
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth={150}
                          verifyOnClick={() => {
                            if (certificateType === "digital") {
                              handleRequestCertificate();
                            } else {
                              setIsStepNumber(3);
                              handleStartAddressAutocomplete();
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : isStepNumber === 3 ? (
                    <div className="mb-8 flex flex-col gap-y-8">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">CEP *</label>
                        <Input
                          type="text"
                          name="zipCode"
                          value={addressInfosForm.zipCode}
                          onChange={handleAddressChange}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full h-full">
                        <label className="span-small-white">Rua *</label>
                        <Input
                          type="text"
                          name="street"
                          value={addressInfosForm.street}
                          onChange={handleAddressChange}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>

                      <div className="flex gap-x-4 w-full">
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">Número *</label>
                          <Input
                            type="text"
                            name="number"
                            value={addressInfosForm.number}
                            onChange={handleAddressChange}
                            optionalIcon={<HiMiniMapPin />}
                          />
                        </div>
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">
                            Complemento
                          </label>
                          <Input
                            type="text"
                            name="complement"
                            value={addressInfosForm.complement}
                            onChange={handleAddressChange}
                            optionalIcon={<HiMiniMapPin />}
                            limitCharacter={30}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Bairro *</label>
                        <Input
                          type="text"
                          name="district"
                          value={addressInfosForm.district}
                          onChange={handleAddressChange}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Cidade *</label>
                        <Input
                          type="text"
                          name="city"
                          value={addressInfosForm.city}
                          onChange={handleAddressChange}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Estado *</label>
                        <SelectElement
                          name="state"
                          options={UFs}
                          disableGroups
                          value={addressInfosForm.state}
                          onChange={handleAddressChange}
                        />
                      </div>
                      <div className="flex justify-end mt-4 gap-x-6 w-full max-mc:pb-8">
                        <ButtonPrimary
                          disableHorizontalMovement
                          optionalHoverBackground="#FFFFFF08"
                          optionalBackground="#00000000"
                          textButton="Anterior"
                          optionalTextColor={certifier.colors.text.textPrimary}
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth="calc(50% - 12px)"
                          verifyOnClick={() => {
                            setIsStepNumber(2);
                          }}
                        />
                        <ButtonPrimary
                          textButton="Próximo"
                          mobileWidth="calc(50% - 12px)"
                          desktopWidth="calc(50% - 12px)"
                          disabled={
                            !(
                              addressInfosForm.zipCode !== "" &&
                              addressInfosForm.street !== "" &&
                              addressInfosForm.number !== "" &&
                              addressInfosForm.district !== "" &&
                              addressInfosForm.city !== "" &&
                              addressInfosForm.state !== "" &&
                              !wrongZipCode
                            )
                          }
                          verifyOnClick={handlePaymentForm}
                        />
                      </div>
                    </div>
                  ) : (
                    //  : isStepNumber === 3 ? (
                    //   <div>
                    //     <CreditCardForm type="certificate" />
                    //   </div>
                    // )
                    isStepNumber === 10 && (
                      <div className="my-8 flex flex-col gap-y-8">
                        <div className="flex flex-col gap-y-2 w-full items-center">
                          <span className="span-small-white w-full">
                            {/* Alteração pertecente à atualização de integração ao Joel Jota */}
                            {transit !== null ||
                            delivered !== null ||
                            selectedCourseOption.courseId ===
                              "67acee9b22a8d5001205a121"
                              ? "Certificado"
                              : "Declaração de Conclusão"}
                          </span>
                          <div className="flex flex-col w-full gap-y-5 items-center">
                            <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                          </div>
                          <span
                            className="span-small-white w-full"
                            style={{
                              textAlign: "center",
                              fontWeight: "normal"
                            }}>
                            {/* Alteração pertecente à atualização de integração ao Joel Jota */}
                            {transit !== null ||
                            delivered !== null ||
                            selectedCourseOption.courseId ===
                              "67acee9b22a8d5001205a121"
                              ? "certificado"
                              : "declaração"}
                            .pdf
                          </span>
                          <div
                            className={`my-2 flex justify-center ${
                              /* Alteração pertecente à atualização de integração ao Joel Jota */
                              transit !== null ||
                              delivered !== null ||
                              selectedCourseOption.courseId ===
                                "67acee9b22a8d5001205a121"
                                ? "mb-0"
                                : "max-mc:pb-8"
                            }`}>
                            <ButtonSecondary
                              textButton="Baixar"
                              optionalMovingIcon={<HiMiniArrowDownTray />}
                              desktopWidth="120px"
                              verifyOnClick={
                                /* Alteração pertecente à atualização de integração ao Joel Jota */
                                transit !== null ||
                                delivered !== null ||
                                selectedCourseOption.courseId ===
                                  "67acee9b22a8d5001205a121"
                                  ? handleDownloadCertificate
                                  : handleDownloadDeclaration
                              }
                            />
                          </div>
                        </div>
                        {/* Alteração pertecente à atualização de integração ao Joel Jota */}
                        {(transit !== null ||
                          delivered !== null ||
                          selectedCourseOption.courseId ===
                            "67acee9b22a8d5001205a121") && (
                          <div className="flex justify-center max-mc:pb-8">
                            <ButtonSecondary
                              textButton={"Compartilhar no LinkedIn"}
                              optionalMovingIcon={<HiMiniShare />}
                              desktopWidth="260px"
                              verifyOnClick={handleShareLinkedIn}
                              optionalBackground="#0274b3"
                            />
                          </div>
                        )}
                      </div>
                    )
                  )}
                </>
              )}

              {certificateContent.payment.data !== null && (
                <div className="flex flex-col gap-y-8">
                  <div className="flex flex-col gap-y-8 pb-8 border-b-2 border-backgroundTwo">
                    <div className="flex flex-col gap-y-3">
                      <label className="span-small-white">
                        Forma de pagamento
                      </label>
                      <div className="flex flex-col gap-y-2">
                        <SelectButtonFinance
                          text="Cartão de Crédito"
                          value="creditCard"
                          onClick={() => {
                            handleSelectMethod("creditCard");
                          }}
                          selectedMethod={selectedMethod}
                          icon={
                            <img
                              alt="Card"
                              class="w-6 h-5"
                              src="/images/card/Card-white.webp"
                            />
                          }
                          enableBorder
                        />
                        <SelectButtonFinance
                          text="Boleto Bancário"
                          value="bankSlip"
                          onClick={() => {
                            handleSelectMethod("bankSlip");
                          }}
                          icon={
                            <img
                              alt="Boleto"
                              class="w-6 h-5"
                              src="/images/card/Bar-white.webp"
                            />
                          }
                          selectedMethod={selectedMethod}
                          enableBorder
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {selectedMethod === "creditCard" && (
                      <div className="flex flex-col gap-y-3 w-full">
                        <CreditCardForm
                          totalInstallments={1}
                          financesId={[
                            certificateContent.payment.data.financeId
                          ]}
                          type="certificate"
                          totalValue={valueCertificate}
                          studentInfos={studentInfos}
                          method={selectedMethod}
                        />
                      </div>
                    )}
                    {selectedMethod === "bankSlip" && (
                      <div className="flex flex-col gap-y-3 items-center w-full">
                        {financeBankSlipData &&
                          financeBankSlipData.metadata && (
                            <>
                              <img
                                src={barCodeImg(
                                  financeBankSlipData.metadata.barcode
                                )}
                                alt="Código de Barras"
                                className="w-full h-auto bg-white p-2 rounded-xl"
                              />
                              <span className="text-xs pb-8 border-b-2 text-center border-backgroundTwo w-full">
                                {financeBankSlipData.metadata.barcode}
                              </span>

                              <div className="flex flex-col w-full gap-3 mt-8">
                                <ButtonPrimary
                                  optionalMovingIcon={
                                    <HiMiniDocumentDuplicate />
                                  }
                                  disableBorderRadius
                                  textButton="Código de Barras"
                                  optionalBackground="#5867dd"
                                  desktopPadding="16px"
                                  verifyOnClick={() => {
                                    copyText(
                                      financeBankSlipData.metadata.barcode
                                    );
                                    Toastify(
                                      "success",
                                      "Código copiado para área de transferência."
                                    );
                                  }}
                                />
                                <Link
                                  to={`${process.env.REACT_APP_API_PRODUCTION_ADDRESS}/fl-finance/pagarme/financeBillet/${certificateContent.payment.data.financeId}/billet`}
                                  target="_blank">
                                  <ButtonPrimary
                                    optionalMovingIcon={<HiEye />}
                                    disableBorderRadius
                                    textButton="Visualizar Boleto"
                                    optionalBackground="#fff"
                                    optionalTextColor="#5867dd"
                                    desktopPadding="16px"
                                  />
                                </Link>
                                <Link
                                  to={`https://api.whatsapp.com/send?text=${process.env.REACT_APP_API_PRODUCTION_ADDRESS}/fl-finance/pagarme/financeBillet/${certificateContent.payment.data.financeId}/billet`}
                                  target="_blank">
                                  <ButtonPrimary
                                    // absoluteIcon
                                    optionalMovingIcon={<RiWhatsappFill />}
                                    desktopWidth="100%"
                                    disableBorderRadius
                                    textButton="Encaminhar Boleto"
                                    optionalBackground="#32a84e"
                                    desktopPadding="16px"
                                  />
                                </Link>
                              </div>
                              <span className="text-[10px] pb-4 w-full">
                                Ambiente criptografado e 100% seguro.
                              </span>
                              <img
                                alt="Compra Segura"
                                src="/images/assets/CompraSegura.webp"
                              />
                            </>
                          )}
                      </div>
                    )}
                    <div className="pt-8 gap-y-3 w-full max-mc:pb-8">
                      <ButtonPrimary
                        disableHorizontalMovement
                        optionalHoverBackground="#FFFFFF08"
                        optionalBackground="#00000000"
                        textButton="Cancelar solicitação"
                        desktopWidth="70%"
                        mobileWidth={150}
                        verifyOnClick={() => {
                          handleDeleteCancelIssueCertificate(
                            certificateContent.payment.data.financeId
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </main>
  );
}

export default verifyAuth(CertificatePage);
