import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";
import getStudentInfos from "../getStudentInfos/getStudentInfos";
import { Toastify } from "../../components/Toastify/Toastify";

export const getCoursesInProgressRequest = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getCoursesInProgress`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getCoursesRecommendedRequest = async (data) => {
  try {
    const sessionId = data.sessionId;
    const viewAll = data.viewAll || false;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getCoursesRecommended`,
      { viewAll: viewAll },
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    if (viewAll) {
      return {
        data: {
          areaAlias: "recommendedCourses",
          areaDescription: "Cursos recomendados para você.",
          areaName: "Cursos recomendados",
          courses: response
        },
        status: responseCrypt.data.code
      };
    } else {
      return {
        data: response,
        status: responseCrypt.data.code
      };
    }
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getCheckoutMatriculation = async (data) => {
  try {
    const sessionId = data.sessionId;
    const courseId = data.courseId;
    const generate = data.generate;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };

    const cryptoInfos = await CryptoService(
      {
        certifierName: certifier.generalSettings.name,
        courseId,
        generate: generate,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );

    const body = { token: cryptoInfos };

    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/checkoutMatriculation`,
      body,
      config
    );

    return {
      data: response.data.data,
      status: response.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getAreasAndCoursesRequest = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getAreasAndCourses`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getAllAvailableCourses = async (data) => {
  try {
    const sessionId = data.sessionId;
    const typeCourse = data.typeCourse;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getAllAvailableCourses/${typeCourse}`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getAllOriginalsCoursesRequest = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { areaAlias: "softskills", viewAll: false };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getCoursesByAreaAlias`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getAllOthersCoursesRequest = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { areaAlias: "coursesJoelJ", viewAll: false };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getCoursesByAreaAlias`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getCoursesByAreaAlias = async (data) => {
  try {
    const sessionId = data.sessionId;
    const areaAlias = data.areaAlias;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { areaAlias: areaAlias, viewAll: true };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getCoursesByAreaAlias`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getNextStepsRequest = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/nextSteps`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const handlePurchaseCourse = async (courseId, setPurchaseLoading) => {
  try {
    setPurchaseLoading(true);
    const student = await getStudentInfos();
    const sessionId = student.sessionId;
    const response = await getCheckoutMatriculation({
      sessionId,
      courseId,
      generate: true
    });
    const { linkMatriculation } = response.data;
    window.open(linkMatriculation, "_blank");
  } catch (error) {
    Toastify("error", "Falha ao abrir link de checkout.");
  } finally {
    setPurchaseLoading(false);
  }
};

export const getPriceDivisor = async (courseId) => {
  try {
    const student = await getStudentInfos();
    const sessionId = student.sessionId;
    const response = await getCheckoutMatriculation({
      sessionId,
      courseId,
      generate: false
    });
    return response.data
  } catch (error) {
    Toastify("error", "Falha ao abrir link de checkout.");
    return null;
  }
};