import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useParams } from "react-router-dom";
import CourseClassVideoCard from "./CourseClassVideoCard/CourseClassVideoCard";

function ModelClasses({ courseModels }) {
  const { courseAlias } = useParams();
  // const unlockedDisciplines = courseModels.disciplines.filter(
  //   (discipline) => discipline.status === "unlock"
  // ).length;

  return (
    <div className="w-full flex flex-col gap-8">
      {courseModels.disciplines.map((discipline, index) => (
          <section
            key={`section-${index}`}
            className="swiper-cards-progress w-full flex flex-col"
          >
            <div className="flex flex-col gap-3">
              <div className="flex max-[800px]:flex-col justify-between items-end max-[800px]:items-start max-[800px]:gap-y-2">
                <div className="font-semibold text-[1.25rem] text-left flex items-center gap-x-4">
                  <span className="font-bold text-left leading-tight text-2xl max-lg:text-[20px] max-lg:mb-0">
                    {discipline.name}
                  </span>
                </div>
                {discipline.status === "lock" ? (
                  <p className="font-normal flex text-sm">
                    disponível em {discipline.dateUnlock}
                  </p>
                ) : (
                  <div />
                )}
              </div>
              <div className="w-full">
                <Swiper
                  className="w-full"
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-navigation-size": "30px",
                    "--swiper-navigation-top-offset": "0%",
                    "--swiper-navigation-sides-offset": "0px",
                    width: "100%",
                  }}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  modules={[Navigation]}
                  slidesPerView={1.33}
                  slidesPerGroup={1}
                  navigation={true}
                  spaceBetween={18}
                  speed={450}
                  breakpoints={{
                    1025: {
                      centeredSlides: false,
                      centeredSlidesBounds: false,
                      slidesPerView: 4,
                      slidesPerGroup: 2,
                      style: { marginLeft: "0%", width: "100%" },
                    },
                  }}
                >
                  {/* Alteração pertecente à atualização de integração ao Joel Jota */}
                  {discipline.classes.length > 0 &&
                    (discipline.classes)    .filter(item => !(courseModels.courseId === "67acee9b22a8d5001205a121" && item.type === "ebook"))
                    .map((item, index) =>  (
                      <SwiperSlide key={`swiper-${index}`} className="w-full">
                        <CourseClassVideoCard
                          position={index}
                          status={discipline.status}
                          courseAlias={courseAlias}
                          type={item.type}
                          title={item.name}
                          image={item.thumb}
                          classId={item.classId}
                          duration={item.duration}
                          classprogress={item.progress}
                          description={item.description}
                          classConcluded={item.concluded}
                          disciplineId={discipline.disciplineId}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </section>
      ))}
      {/* <div
        key={`div-${index}`}
        className="w-full h-[1px] bg-backgroundThree rounded-full"
      /> */}
    </div>
  );
}

export default ModelClasses;
