// src/store/slices/globalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLiaOpen: false,
  isLiaWorking: false
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setisLiaOpenState: (state, action) => {
      state.isLiaOpen = action.payload;
    },
    toggleLiaOpenState: (state) => {
      state.isLiaOpen = !state.isLiaOpen;
    },
    enableIsLiaWorking: (state) => {
      state.isLiaWorking = true;
    },
  },
});

export const { setisLiaOpenState, toggleLiaOpenState, enableIsLiaWorking } = globalSlice.actions;
export default globalSlice.reducer;
