import { Toastify } from "../../components/Toastify/Toastify";

export const copyText = (text, successfullyToastify) => {
  // Desktop Attempt
  try {
    navigator.clipboard.writeText(text);
    if (successfullyToastify)
      Toastify("success", "Texto copiado para a área de transferência.");
  } catch (error) {
    // Mobile Attempts
    try {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        dummy.contentEditable = true;
        dummy.readOnly = true;

        dummy.focus();
        dummy.select();

        dummy.setSelectionRange(0, 999999);
      } else {
        dummy.select();
      }

      document.execCommand("copy");
      document.body.removeChild(dummy);

      if (successfullyToastify) {
        Toastify("success", "Texto copiado para a área de transferência.");
      }
    } catch (error) {
      Toastify("error", error.message);
    }
  }
};
