import React, { useEffect, useState } from "react";
import "./dashboard.css";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import DashboardVideoSlider from "../../components/VideoSliderElements/DashboardVideoSlider";
import CoursesInProgress from "../../components/CoursesInProgress/CoursesInProgress";
import CoursesRecommended from "../../components/CoursesRecommended/CoursesRecommended";
import CourseList from "../../components/CourseList/CourseList";
import {
  getCoursesInProgressRequest,
  getCoursesRecommendedRequest,
  getAreasAndCoursesRequest,
  getAllOriginalsCoursesRequest,
  // getAllOthersCoursesRequest,
  getNextStepsRequest
} from "../../services/dashboardService/dashboard.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
// import { getAllStudentCourses } from "../../services/courseService/course.service";
import { certifier } from "../../mocks/certifier";
import getCareerState from "../../services/getCareerState/getCareerState";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import CareerPopUp from "../../components/CareerPopUp/CareerPopUp";
import NextSteps from "../../components/NextSteps/NextSteps";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [coursesRecommended, setCoursesRecommended] = useState([]);
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [isAvailableCourses, setIsAvailableCourses] = useState(false);
  // const [studentCourses, setStudentCourses] = useState(undefined);
  const [areasAndCoursesRequest, setAreasAndCoursesRequest] = useState([]);
  const [allOriginalsCourses, setAllOriginalsCourses] = useState([]);
  // const [allOthersCourses, setAllOthersCourses] = useState([]);
  const [nextSteps, setNextSteps] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    const fetchDashboardInfo = async () => {
      setIsLoading(true);
      try {
        const student = await getStudentInfos();

        const careerInfos = await getCareerState();

        if (careerInfos === null && !student.completedCareerInfos) {
          const ciphertext = await CryptoService(
            { lastExecution: new Date() },
            "encrypt-crypto"
          );
          localStorage.setItem("career", ciphertext);
          setShowPopUp(true);
        } else if (!student.completedCareerInfos) {
          let { lastExecution } = careerInfos;
          lastExecution = new Date(lastExecution);
          const now = new Date();
          const diff = now.getTime() - lastExecution.getTime();
          const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
          if (diffDays >= 15) {
            const ciphertext = await CryptoService(
              { lastExecution: new Date() },
              "encrypt-crypto"
            );
            localStorage.setItem("career", ciphertext);
            setShowPopUp(true);
          }
        }

        const arrayCourses = await getCoursesInProgressRequest({
          sessionId: student.sessionId
        });
        if (arrayCourses.status !== 200) {
          Toastify(
            "error",
            "Erro ao buscar informações de cursos em progresso."
          );
          console.error(
            "Erro ao buscar informações de cursos em progresso - 1:",
            arrayCourses.status
          );
        }
        // setCoursesInProgress --- END
        // getAllStudentCourses --- START
        // const arrayStudentCourses = await getAllStudentCourses({
        //   sessionId: student.sessionId
        // });
        // if (arrayStudentCourses.status !== 200) {
        //   Toastify("error", "Erro ao buscar informações de seus cursos.");
        //   console.error(
        //     "Erro ao buscar informações de seus cursos - 1:",
        //     arrayStudentCourses.status
        //   );
        // }
        // getAllStudentCourses --- END
        // setCoursesRecommended --- START
        const arrayCoursesRecommended = await getCoursesRecommendedRequest({
          sessionId: student.sessionId,
          viewAll: false
        });
        if (
          arrayCoursesRecommended.status !== 200 &&
          arrayCoursesRecommended.status !== 404
        ) {
          // Toastify(
          //   "error",
          //   "Erro ao buscar informações de cursos recomendados."
          // );
          console.error(
            "Erro ao buscar informações de cursos recomendados - 2:",
            arrayCoursesRecommended.status
          );
        }
        // setCoursesRecommended --- END
        // setAreasAndCoursesRequest --- START
        const arrayAreasAndCourses = await getAreasAndCoursesRequest({
          sessionId: student.sessionId
        });
        if (arrayAreasAndCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de áreas e cursos.");
          console.error(
            "Erro ao buscar informações de áreas e cursos - 3:",
            arrayAreasAndCourses
          );
        }
        // setAreasAndCoursesRequest --- END
        // setOriginalsCourseRequest --- START
        if (certifier.generalSettings.foldername === "faculdadelibano") {
          const arrayAllOriginalsCourses = await getAllOriginalsCoursesRequest({
            sessionId: student.sessionId
          });
          if (arrayAllOriginalsCourses.status !== 200) {
            Toastify(
              "error",
              "Erro ao buscar informações de cursos originais."
            );
            console.error(
              "Erro ao buscar informações de cursos originais - 4:",
              arrayAllOriginalsCourses
            );
          }
          try {
            if (arrayAllOriginalsCourses.data !== undefined) {
              setAllOriginalsCourses([arrayAllOriginalsCourses.data]);
            } else {
              Toastify("error", "Erro ao processar itens da dashboard: #05");
            }
          } catch {
            Toastify("error", "Erro ao processar itens da dashboard: #06");
          }
        }

        // setOriginalsCourseRequest --- END
        // getNextStepsRequest --- START
        const arrayNextSteps = await getNextStepsRequest({
          sessionId: student.sessionId
        });
        if (arrayNextSteps.status !== 200) {
          Toastify("error", "Erro ao buscar informações de steps.");
          console.error(
            "Erro ao buscar informações de steps - 9:",
            arrayNextSteps
          );
        }
        try {
          if (arrayNextSteps.data !== undefined) {
            const availableSteps = arrayNextSteps.data.filter(
              (item) => item.status === true
            );

            if (availableSteps.length > 0) {
              availableSteps.sort(() => Math.random() - 0.5);
              const nextStep = availableSteps[0];
              if (nextStep.type === "availableCourse") {
                const courseType =
                  nextStep.details.type && nextStep.details.type[0]
                    ? nextStep.details.type[0]
                    : null;

                setIsAvailableCourses(courseType);
                setNextSteps({
                  text: nextStep.details.description,
                  link: "/availableCourses",
                  image: `/images/nextsteps/${nextStep.type}.webp`,
                  description: "Expanda suas oportunidades!",
                  button: "Resgatar Agora"
                });
              } else if (nextStep.type === "availableDocs") {
                setNextSteps({
                  text: nextStep.details.description,
                  link: `/user/documents`,
                  image: `/images/nextsteps/${nextStep.type}.webp`,
                  description: "Descricao da page de documentos",
                  button: "Envie seus documentos"
                });
              } else if (nextStep.type === "availableContract") {
                setNextSteps({
                  text: nextStep.details.description,
                  link: `/user/contracts`,
                  image: `/images/nextsteps/${nextStep.type}.webp`,
                  description: "Descricao de contratos",
                  button: "Assinar"
                });
              } else if (nextStep.type === "availableTCC") {
                setNextSteps({
                  text: nextStep.details.description,
                  link: `/tcc`,
                  image: `/images/nextsteps/${nextStep.type}.webp`,
                  description: "Descricao de TCC",
                  button: "Saiba Mais"
                });
              } else if (nextStep.type === "availableFinance") {
                setNextSteps({
                  text: nextStep.details.description,
                  link: `/finance`,
                  image: `/images/nextsteps/${nextStep.type}.webp`,
                  description: "Tem conta atrasada",
                  button: "Veja Mais"
                });
              }
            }
          } else {
            Toastify("error", "Erro ao processar itens da dashboard: #07");
          }
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #08");
        }
        // getNextStepsRequest --- END
        try {
          setCoursesInProgress(arrayCourses.data);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #01");
        }
        try {
          setCoursesRecommended(arrayCoursesRecommended.data);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #03");
        }
        try {
          setAreasAndCoursesRequest(arrayAreasAndCourses.data);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #04");
        }
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar cursos na dashboard.");
        console.error("Erro ao buscar cursos na dashboard: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDashboardInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main
      style={{ marginBottom: `${windowWidth <= 1024 ? navBar : 0}px` }}
      className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      {!isLoading && showPopUp && (
        <CareerPopUp handleClosePopUp={() => setShowPopUp(false)} />
      )}
      <div
        className={`w-full justify-center items-center overflow-x-hidden ${
          windowWidth < 1024
            ? "overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
            : "flex flex-col"
        }`}>
        <HeaderBar
          courseNavigation={true}
          disableBackButton
          actualPage={"Início"}
        />
        <MobileNavigationBar actualPage="Início" />
        <LoadingSection isLoading={isLoading} />
        {!isLoading && <DashboardVideoSlider />}
        <section className="flex flex-col max-w-[1170px] justify-center items-center h-auto max-lg:px-[5vw] max-lg:max-w-full">
          <section className="w-full flex flex-col gap-10 mb-10 max-lg:overflow-hidden max-lg:gap-8">
            {coursesInProgress && coursesInProgress.length > 0 && (
              <section className="w-full flex">
                <CoursesInProgress
                  courses={coursesInProgress}
                  routeRedirect={"/studentCourses"}
                />
              </section>
            )}

            {nextSteps && <NextSteps nextSteps={nextSteps} />}

            {coursesRecommended && coursesRecommended.length > 0 && (
              <section className="w-full flex justify-center">
                <CoursesRecommended
                  courses={coursesRecommended}
                  isAvailableCourses={
                    isAvailableCourses !== "cursosLivres" && isAvailableCourses
                  }
                />
              </section>
            )}

            {areasAndCoursesRequest &&
              areasAndCoursesRequest.length > 0 &&
              areasAndCoursesRequest.map((area, index) => (
                <section key={index} className="w-full flex justify-center">
                  <CourseList
                    isAvailableCourses={
                      isAvailableCourses !== "cursosLivres" &&
                      isAvailableCourses
                    }
                    seeAllContent={isAvailableCourses}
                    area={area}
                  />
                </section>
              ))}

            {allOriginalsCourses &&
              allOriginalsCourses.length > 0 &&
              allOriginalsCourses.map((area, index) => (
                <section key={index} className="w-full flex justify-center">
                  <CourseList
                    isAvailableCourses={isAvailableCourses}
                    seeAllContent={isAvailableCourses}
                    area={area}
                  />
                </section>
              ))}
          </section>
        </section>
      </div>
    </main>
  );
}

export default verifyAuth(Dashboard);
