import "./styles/App.css";
import "./styles/WhiteLabel.css";
import "./styles/colors.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomePage from "./pages/homeRedirect/homeRedirect";
import Login from "./pages/login/login";
import Logout from "./pages/logout/logout.jsx";
import RecoveryPass from "./pages/recoveryPass/recoveryPass";
import NewRecovery from "./pages/newRecovery/newRecovery";
import Dashboard from "./pages/dashboard/dashboard";
import Course from "./pages/course/course";
import StudentCourses from "./pages/studentCourses/studentCourses";
import DeclarationsPage from "./pages/declarations/declarations";
import ContractPage from "./pages/contract/contract";
import ClassPage from "./pages/classPage/classPage";
import TitleAndSubtitle from "./components/TitleAndSubtitle/TitleAndSubtitle";
import Page404 from "./pages/Page404/Page404";
import AvaliationsPage from "./pages/avaliations/avaliations";
import CertificatePage from "./pages/certificate/certificate";
import DocumentsPage from "./pages/documents/documents";
import TCCPage from "./pages/tcc/tcc";
import BasicInfosPage from "./pages/basicInfos/basicInfos";
import SpecifiedAreaPage from "./pages/specifiedAreaPage/specifiedAreaPage";
import FinancePage from "./pages/finance/finance";
import InsideAvaliation from "./pages/insideAvaliation/insideAvaliation";
import WhatsappRedirect from "./pages/whatsappRedirect/whatsappRedirect";
import ExternalLoginRedirect from "./pages/externalLoginRedirect/externalLoginRedirect";
import { Analytics } from "@vercel/analytics/react";
import AllAvailableCoursesPage from "./pages/allAvailableCoursesPage/allAvailableCoursesPage";
import RateCoursePage from "./pages/rateCoursePage/rateCoursePage";
import SearchAreaPage from "./pages/searchAreaPage/searchAreaPage";
import NewCourse from "./pages/newCourse/newCourse";
import DevBox from "./components/DevBox/DevBox";
import { store } from "./store";
import { Provider } from "react-redux";
import FloatSpan from "./components/FloatSpan/FloatSpan.jsx";
import ManualPlayer from "./pages/manualPlayer/manualPlayer.jsx";

function App() {
  return (
    <Provider store={store}>
      <main className="App">
        <Analytics />
        <ToastContainer />
        {process.env.REACT_APP_ENVIRONMENT !== "production" ? <DevBox /> : null}
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Redirecionando..." /> <HomePage />
                </>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Entrar" /> <Login />
                </>
              }
            />
            <Route
              path="/logout"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Saindo..." /> <Logout />
                </>
              }
            />
            <Route
              path="/recoveryPass"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Recuperação de Senha" />
                  <RecoveryPass />
                </>
              }
            />
            <Route
              path="/newRecovery"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Nova Senha" />
                  <NewRecovery />
                </>
              }
            />
            <Route
              path="/dashboard"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Início" />
                  <Dashboard />
                </>
              }
            />
            <Route
              path="/course/:courseAlias"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Curso" />
                  <Course />
                </>
              }
            />
            <Route
              path="/availableCourses"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Cursos Disponíveis" />
                  <AllAvailableCoursesPage />
                </>
              }
            />
            <Route
              path="/purchaseCourse/:typeCourse/:courseAlias"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Adquira este curso" />
                  <NewCourse purchaseType />
                </>
              }
            />
            <Route
              path="/rescueCourse/:typeCourse/:courseAlias"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Resgate este curso" />
                  <NewCourse />
                </>
              }
            />
            <Route
              path="/studentCourses"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Meus Cursos" />
                  <StudentCourses />
                </>
              }
            />
            <Route
              path="/searchArea"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Cursos Específicos da Área" />
                  <SearchAreaPage />
                </>
              }
            />
            <Route
              path="/recommendedCourses"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Cursos Recomendados" />
                  <SpecifiedAreaPage recommendedCourses={true} />
                </>
              }
            />
            <Route
              path="/course/:courseAlias/:disciplineId/:classId"
              element={
                <>
                  <FloatSpan pageHasLia={true} /> <TitleAndSubtitle title="Aula" />
                  <ClassPage />
                </>
              }
            />
            <Route
              path="/demoCourse/:typeCourse/:courseAlias"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Demonstração de Curso" />
                  <ClassPage demoCourse />
                </>
              }
            />
            <Route
              path="/user/declarations"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Declarações" />
                  <DeclarationsPage />
                </>
              }
            />
            <Route
              path="/user/contracts"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Contrato" />
                  <ContractPage />
                </>
              }
            />
            <Route
              path="/user/certificates"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Certificado" />
                  <CertificatePage />
                </>
              }
            />
            <Route
              path="/user/documents"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Documentos" />
                  <DocumentsPage />
                </>
              }
            />
            <Route
              path="/tcc"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Trabalho de Conclusão de Curso" />
                  <TCCPage />
                </>
              }
            />
            <Route
              path="/rateCourse/:courseAlias"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Avaliação do Curso" />
                  <RateCoursePage />
                </>
              }
            />
            <Route
              path="/user/basicInfos"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Informações Básicas" />
                  <BasicInfosPage />
                </>
              }
            />
            <Route
              path="/avaliation"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Avaliação" />
                  <InsideAvaliation />
                </>
              }
            />
            <Route
              path="/avaliations"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Avaliações Online" />
                  <AvaliationsPage />
                </>
              }
            />
            <Route
              path="/finance"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Financeiro" />
                  <FinancePage />
                </>
              }
            />
            <Route path="/support" element={<WhatsappRedirect />} />
            <Route path="/manual-player" element={<ManualPlayer />} />
            <Route
              path="*"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Página Não Encontrada." />
                  <Page404 />
                </>
              }
            />
            <Route
              path="/externalLogin/:firstAccessKey"
              element={
                <>
                  <FloatSpan /> <TitleAndSubtitle title="Redirecionando...." />
                  <ExternalLoginRedirect />
                </>
              }
            />
          </Routes>
        </Router>
      </main>
    </Provider>
  );
}

export default App;
