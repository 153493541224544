import React, { useState, useRef, useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default function ManualPlayer() {
  const [videoUrl, setVideoUrl] = useState("https://storage.googleapis.com/bucket-fl-storage/uploadFile/sandbox/system/upload/50898a8f-a9da-43bf-bf85-5f7d8a362d93/TRAILER%201920X1080%20KAREN.mp4");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoNode = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    if (isPlaying && videoNode.current) {
      player.current = videojs(videoNode.current, {
        controls: true,
        autoplay: true,
        preload: "auto",
      });

      player.current.src({ src: videoUrl, type: "video/mp4" });

      return () => {
        if (player.current) {
          player.current.dispose();
        }
      };
    }
  }, [isPlaying, videoUrl]);

  const handlePlayVideo = () => {
    if (videoUrl.trim() !== "") {
      setIsPlaying(true);
    }
  };

  return (
    <div className="flex flex-col w-full h-full items-center justify-center p-[5vw] bg-black">
      <h1 className="text-white mb-4">Insira o URL para renderizarmos</h1>
      <input
        type="text"
        value={videoUrl}
        onChange={(e) => {setVideoUrl(e.target.value);setIsPlaying(false)}}
        placeholder="Digite o URL do vídeo"
        className="p-2 rounded mb-4 w-full max-w-md"
      />
      <button
        onClick={handlePlayVideo}
        className="bg-blue-500 text-white p-2 rounded mb-4"
      >
        Carregar Vídeo
      </button>
      {isPlaying && videoUrl && (
        <div data-vjs-player className="w-full max-w-lg">
          <video ref={videoNode} className="video-js vjs-default-skin w-full aspect-video h-auto" />
        </div>
      )}
    </div>
  );
}
