import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier"

export const getStepsIssueCertificate = async ({
  studentInfos,
  matriculationId,
  courseId,
  disciplineId
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: matriculationId,
        disciplineId: disciplineId ? disciplineId : undefined,
        courseId: courseId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/stepsIssueCertificate`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getStudentPortal = async ({ studentInfos }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getStudentPortal`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getUpdateStudentPortal = async ({ studentInfos, student }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        student: student,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/updateStudentPortal`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getIssueCertificate = async ({ studentInfos, data }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: data.matriculationId,
        courseId: data.courseId,
        disciplineId: data.disciplineId ? data.disciplineId : undefined,
        typeCertificate: data.typeCertificate,
        shipping: data.shipping,
        address: data.address,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/issueCertificate`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const postPaymentCertificate = async ({ studentInfos, data, type }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    let dataCryp = null;

    if (type === "bankSlip") {
      dataCryp = {
        method: "Boleto",
        selectId: data.selectId,
        value: data.value,
        exp: moment().add(2, "hours").unix(),
      };
    } else if (type === "creditCard") {
      dataCryp = {
        cardHash: data.cardHash,
        method: "Cartão",
        selectId: data.selectId,
        value: data.value,
        exp: moment().add(2, "hours").unix(),
      };
    }

    const cryptoInfos = await CryptoService(dataCryp, "encrypt-jwt");
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/paymentCertificate`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    try {
      const responseDecrypt = await CryptoService(
        err.response.data.data.data,
        "decrypt-json"
      );

      return { status: 420, data: responseDecrypt.error.data.error.data };
    } catch (err) {
      return { status: err.response.data.code, data: null };
    }
  }
};

export const deleteCancelIssueCertificate = async ({
  studentInfos,
  financeId,
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );

    let dataCryp = {
      financeId: financeId,
      exp: moment().add(2, "hours").unix(),
    };

    const cryptoInfos = await CryptoService(dataCryp, "encrypt-jwt");
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/cancelIssueCertificate`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};
