import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";
import getStudentInfos from "../getStudentInfos/getStudentInfos";

function handleError(err) {
  if (err?.response && String(err.response.status) === "401") {
    Sentry.setUser(null);
    localStorage.removeItem("user");
    if (window) window.location.reload();
    return;
  }
  return { status: err?.response?.data?.code };
}

async function encryptData(data) {
  return await CryptoService(
    {
      ...data,
      exp: moment().add(2, "hours").unix()
    },
    "encrypt-jwt"
  );
}

async function getAuthHeader() {
  const student = await getStudentInfos();
  const sessionId = student.sessionId;

  const token = await encryptData({
    sessionId,
    certifierName: certifier.generalSettings.name
  });

  return {
    headers: {
      auth: token
    }
  };
}

async function makeRequest(url, method = "GET", body = null) {
  const config = await getAuthHeader();
  let responseCrypt;

  if (method === "GET") responseCrypt = await axios.get(url, config);
  else responseCrypt = await axios.post(url, body, config);

  const response = await CryptoService(responseCrypt.data.data, "decrypt-json");
  if (responseCrypt.data.code !== 200) return { status: response.data.code };

  return {
    data: response,
    status: responseCrypt.data.code
  };
}

async function requestWithEncryptedPayload(endpoint, payload) {
  const cryptoInfos = await encryptData(payload);
  const body = { token: cryptoInfos };
  return makeRequest(
    `${process.env.REACT_APP_API_ADDRESS}/${endpoint}`,
    "POST",
    body
  );
}

export const requestLiaStatus = async () => {
  try {
    return await makeRequest(`${process.env.REACT_APP_API_ADDRESS}/liaStatus`);
  } catch (err) {
    return handleError(err);
  }
};

export const requestRegenerateLiaMessage = async (data) => {
  try {
    return await requestWithEncryptedPayload("regenerateLiaMessage", {
      threadId: data.threadId,
      courseId: data.courseId,
      liaMessageId: data.liaMessageId
    });
  } catch (err) {
    return handleError(err);
  }
};

export const requestRegenerateUserMessage = async (data) => {
  try {
    return await requestWithEncryptedPayload("regenerateUserMessage", {
      threadId: data.threadId,
      courseId: data.courseId,
      userMessageId: data.userMessageId,
      newMessage: data.newMessage
    });
  } catch (err) {
    return handleError(err);
  }
};

export const requestLiaChats = async (data) => {
  try {
    return await requestWithEncryptedPayload("getLiaChats", {
      courseId: data.courseId
    });
  } catch (err) {
    return handleError(err);
  }
};

export const requestSpecifiedLiaChat = async (data) => {
  try {
    return await requestWithEncryptedPayload("getSpecifiedLiaChat", {
      threadId: data.threadId
    });
  } catch (err) {
    return handleError(err);
  }
};

export const requestNewLiaMessage = async (data) => {
  try {
    return await requestWithEncryptedPayload("newLiaMessage", {
      message: data.message,
      courseId: data.courseId,
      userInfos: data.userInfos,
      existingThreadId: data.existingThreadId
    });
  } catch (err) {
    return handleError(err);
  }
};

export const requestLiaRunStatus = async (data) => {
  try {
    return await requestWithEncryptedPayload("getLiaRunStatus", {
      runId: data.runId,
      threadId: data.threadId
    });
  } catch (err) {
    return handleError(err);
  }
};

export const requestDeleteLiaThread = async (data) => {
  try {
    return await requestWithEncryptedPayload("deleteLiaThread", {
      threadId: data.threadId
    });
  } catch (err) {
    return handleError(err);
  }
};
