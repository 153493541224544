// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './slices/globalSlice';

export const store = configureStore({
  reducer: {
    global: globalReducer,
  },
});

export default store;
