import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { getListStudentCourses } from "../../services/courseService/course.service";
import Cookies from "js-cookie";
import SelectElement from "../../components/SelectElement/MainSelect";
import {
  HiMiniDocumentDuplicate,
  HiEye,
  HiMiniShieldCheck,
} from "react-icons/hi2";
import { RiWhatsappFill } from "react-icons/ri";
import SelectButtonFinance from "../../components/SelectButtonFinance/SelectButtonFinance";
import CreditCardForm from "../../components/CreditCardComponents/CreditCardForm";
import { barCodeImg } from "../../services/barCodeService/barCode.service";
import { getFinanceList } from "../../services/financeService/finance.service";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import FinanceTablePaid from "../../components/FinanceTablePaid/FinanceTablePaid";
import FinanceTablePending from "../../components/FinanceTablePending/FinanceTablePending";
import { Link } from "react-router-dom";
import { postGenerateBilletPayment } from "../../services/financeService/finance.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";
import { copyText } from "../../services/copyText/copyText";

function FinancePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [selectedCourseOption, setSelectedCourseOption] = useState({});
  const [selectedMethod, setSelectedMethod] = useState("creditCard");
  const [financesPaid, setFinancesPaid] = useState([]);
  const [financesPending, setFinancesPending] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [selectedFinances, setSelectedFinances] = useState([]);
  const [financeBankSlipData, setfinanceBankSlipData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);
  const [visiblePaymentButton, setVisiblePaymentButton] = useState(true);

  useEffect(() => {
    async function fetchStudentCourses() {
      setIsLoading(true);
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);

        const student = await getStudentInfos();
        setStudentInfos(student);

        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId,
        });

        if (coursesResponse.status !== 200) {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }

        try {
          const allCourses = coursesResponse.data.map((course) => ({
            label: course.courseName,
            value: course.courseAlias,
            courseId: course.courseId,
            matriculationId: course.matriculationId,
            typeCourse: course.typeCourse,
          }));

          const lastCourseAccessed = Cookies.get("lastCourseAccessed_finance");
          const lastCourse = allCourses.find(
            (course) => course.value === lastCourseAccessed
          );

          if (lastCourse) {
            setSelectedCourseOption(lastCourse);
            await getFinances(lastCourse, student);
          } else {
            setSelectedCourseOption(allCourses[0]);
            await getFinances(allCourses[0], student);
          }

          setCoursesOptions(allCourses);
        } catch (error) {
          Toastify("error", "Erro ao processar nomes dos cursos: #01");
        }

        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
      }
      setIsLoading(false);
    }

    fetchStudentCourses();
  }, []);

  const handleSelectCourseChange = async (e) => {
    setSelectedCourseOption(e);
    setIsLoading(true);
    await getFinances(e, studentInfos);
    Cookies.set("lastCourseAccessed_finance", e.value, {
      expires: 1 / 24,
    });
    setIsLoading(false);
  };

  const getFinances = async (course, student) => {
    setIsLoading(true);
    setFinancesPaid([]);
    setFinancesPending([]);
    setTotalValue(0);
    setSelectedFinances([]);
    setfinanceBankSlipData(null);

    try {
      const finances = await getFinanceList({
        sessionId: student.sessionId,
        matriculationId: course.matriculationId,
      });

      setFinancesPaid(finances.data.financesPaid);
      setFinancesPending(finances.data.financesPending);
    } catch (error) {
      Toastify("error", "Erro generalizado ao buscar as cobranças - 2.");
    }
  };

  const handleCountTotalValue = (value) => {
    setTotalValue(value);
  };

  const handleFinancesSelected = (array) => {
    setfinanceBankSlipData(null);
    setSelectedMethod("creditCard");
    setSelectedFinances(array);
  };

  const valuePTBR = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const generateBilletPayment = async () => {
    setfinanceBankSlipData(null);
    let financeBankSlip = await postGenerateBilletPayment({
      sessionId: studentInfos.sessionId,
      matriculationId: selectedCourseOption.matriculationId,
      ids: selectedFinances,
    });
    if (financeBankSlip.status === 200) {
      if (financeBankSlip.data) {
        try {
          financeBankSlip.data[0].paymentPlan.paymentMethods.forEach(
            (method) => {
              if (method.alias === "Boleto") {
                method.financeId = financeBankSlip.data._id;
                setfinanceBankSlipData(method);
              }
            }
          );
        } catch (err) {
          try {
            financeBankSlip.data.paymentPlan.paymentMethods.forEach(
              (method) => {
                if (method.alias === "Boleto") {
                  method.financeId = financeBankSlip.data._id;
                  setfinanceBankSlipData(method);
                }
              }
            );
          } catch (err) {
            Toastify("error", "Erro ao gerar boleto.");
          }
        }
      }
    } else {
      Toastify("error", "Erro ao gerar boleto.");
    }
  };

  const handleSelectMethod = async (method) => {
    setSelectedMethod(method);
    setIsLoading(true);
    if (method === "bankSlip") {
      await generateBilletPayment();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const navBar = document.getElementById("mobile-navigation");
      const financePaymentForm = document.getElementById(
        "finance-payment-form"
      );
      if (!financePaymentForm || !navBar) return;
      if (
        window.scrollY + window.innerHeight - navBar.offsetHeight >
        financePaymentForm.offsetTop
      ) {
        setVisiblePaymentButton(false);
      } else {
        setVisiblePaymentButton(true);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne min-h-screen items-center text-textPrimary max-mc:bg-backgroundThree">
      <HeaderBar
        courseNavigation={false}
        actualPage="Financeiro"
        mobileMainText="Financeiro"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Financeiro" />
      <LoadingSection isLoading={isLoading} />
      <section
        className={`overflow-hidden flex w-full h-full max-w-[1170px] justify-center min-h-[600px] bg-backgroundTwo max-mc:bg-backgroundThree my-9 rounded-xl max-mc:my-0 max-mc:flex-col max-mc:rounded-none`}
      >
        <div
          style={{
            paddingTop: windowWidth < 960 ? navBarHeader : 0,
            paddingBottom: windowWidth < 960 ? navBar : 0,
          }}
          className={`w-full ${
            windowWidth < 960 ? "h-[auto]" : "flex justify-between"
          }`}
        >
          <div
            className={`relative flex flex-col bg-backgroundTwo w-[65%] gap-y-20 p-20 py-16 max-mc:bg-backgroundThree max-mc:w-full max-mc:p-8 max-mc:gap-y-8`}
          >
            <div className={`max-mc:flex flex-col gap-8 w-full hidden`}>
              <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                Financeiro
              </h1>
              <div className="flex flex-col gap-y-3 w-full h-full pb-8 border-b-2 border-black/30">
                <label className="span-small-white">Selecione o curso</label>
                <SelectElement
                  options={coursesOptions}
                  value={selectedCourseOption ? selectedCourseOption.value : ""}
                  onChange={handleSelectCourseChange}
                />
              </div>
            </div>

            {financesPaid.length > 0 && (
              <div
                className={`max-mc:bg-backgroundTwo max-mc:rounded-2xl max-mc:py-6`}
              >
                <FinanceTablePaid finances={financesPaid} />
              </div>
            )}

            {financesPending.length > 0 && (
              <div
                className={`max-mc:bg-backgroundTwo max-mc:rounded-2xl max-mc:py-6`}
              >
                <FinanceTablePending
                  onValueChange={handleCountTotalValue}
                  onFinancesSelected={handleFinancesSelected}
                  finances={financesPending}
                />
              </div>
            )}

            {financesPaid.length === 0 && financesPending.length === 0 && (
              <span className="text-3xl text-textPrimary font-bold leading-[1.35] w-full text-center">
                Não há cobranças!
              </span>
            )}
          </div>
          <div
            className={`flex bg-backgroundThree flex-col gap-y-8 w-[38%] p-16 max-mc:w-full max-mc:p-8`}
          >
            <h1
              className={`flex text-3xl text-textPrimary font-bold leading-[1.35] max-mc:hidden`}
            >
              Financeiro
            </h1>
            <div id="finance-payment-form" className="flex flex-col gap-y-8">
              <div
                className={`flex flex-col gap-y-3 w-full h-full pb-8 border-b-2 border-black/30 max-mc:hidden`}
              >
                <label className="span-small-white">Selecione o curso</label>
                <SelectElement
                  options={coursesOptions}
                  value={selectedCourseOption ? selectedCourseOption.value : ""}
                  onChange={handleSelectCourseChange}
                />
              </div>
              {totalValue > 0 && (
                <>
                  <div
                    style={{
                      marginBottom: windowWidth < 960 ? navBar : 0,
                      display: visiblePaymentButton ? "block" : "none",
                    }}
                    className="fixed bottom-0 left-0 w-full h-[5rem] backdrop-blur-[1rem] z-[10] hidden max-mc:flex"
                  >
                    <div className="flex items-center justify-center w-full h-full px-[5vw]">
                      <ButtonPrimary
                        optionalBackground="#08bf4f"
                        optionalTextColor="#FFF"
                        optionalMovingIcon={<HiMiniShieldCheck />}
                        disableBorderRadius
                        desktopPadding="16px"
                        mobilePadding="16px"
                        textButton="Efetuar Pagamento"
                        verifyOnClick={() => {
                          const financePaymentForm = document.getElementById(
                            "finance-payment-form"
                          );
                          if (!financePaymentForm) return;
                          window.scrollTo({
                            top: financePaymentForm.offsetHeight - navBarHeader,
                            behavior: "smooth",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-8 pb-8 border-b-2 border-black/30">
                    <div className="flex gap-2">
                      <h2 className="text-xl text-textPrimary font-bold leading-[1.35]">
                        Total Selecionado:
                      </h2>
                      <span className="text-xl font-normal text-textPrimary leading-[1.35]">
                        R$ {valuePTBR(totalValue / 100)}
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-3">
                      <label className="span-small-white">
                        Selecione a forma de pagamento
                      </label>
                      <div className="flex flex-col gap-y-2">
                        <SelectButtonFinance
                          text="Cartão de Crédito"
                          value="creditCard"
                          onClick={() => {
                            handleSelectMethod("creditCard");
                          }}
                          icon={
                            <img
                              alt="Card"
                              className="w-6 h-5"
                              src="/images/card/Card-white.webp"
                            />
                          }
                          selectedMethod={selectedMethod}
                          enableBorder
                        />
                        {financesPending[0]?.methodPayment === "Boleto" && (
                          <div>
                            <SelectButtonFinance
                              text="Boleto Bancário"
                              value="bankSlip"
                              onClick={() => {
                                handleSelectMethod("bankSlip");
                              }}
                              icon={
                                <img
                                  alt="White bar"
                                  className="w-6 h-5"
                                  src="/images/card/Bar-white.webp"
                                />
                              }
                              selectedMethod={selectedMethod}
                              enableBorder
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div>
                {selectedMethod === "creditCard" && (
                  <div className="flex flex-col gap-y-3 items-center w-full">
                    {selectedFinances.length > 0 && (
                      <CreditCardForm
                        totalInstallments={selectedFinances.length}
                        financesId={selectedFinances}
                        type="finance"
                        totalValue={totalValue / 100}
                        studentInfos={studentInfos}
                        selectedCourseOption={selectedCourseOption}
                        method={financesPending[0]?.methodPayment}
                      />
                    )}
                  </div>
                )}
                {selectedMethod === "bankSlip" && (
                  <div className="flex flex-col gap-y-3 items-center w-full">
                    {financeBankSlipData && financeBankSlipData.metadata && (
                      <>
                        <img
                          src={barCodeImg(financeBankSlipData.metadata.barcode)}
                          alt="Código de Barras"
                          className="w-full h-auto bg-white p-2 rounded-xl"
                        />
                        <span className="text-xs pb-8 border-b-2 text-center border-black/30 w-full">
                          {financeBankSlipData.metadata.barcode}
                        </span>

                        <div className="flex flex-col w-full gap-3 mt-8">
                          <ButtonPrimary
                            optionalMovingIcon={<HiMiniDocumentDuplicate />}
                            disableBorderRadius
                            textButton="Código de Barras"
                            optionalBackground="#5867dd"
                            desktopPadding="16px"
                            mobilePadding="16px"
                            verifyOnClick={() => {
                              copyText(
                                financeBankSlipData.metadata.barcode
                              );
                              Toastify(
                                "success",
                                "Código copiado para área de transferência."
                              );
                            }}
                          />
                          <Link
                            to={`${process.env.REACT_APP_API_PRODUCTION_ADDRESS}/fl-finance/pagarme/financeBillet/${financeBankSlipData.financeId}/billet`}
                            target="_blank"
                          >
                            <ButtonPrimary
                              optionalMovingIcon={<HiEye />}
                              disableBorderRadius
                              textButton="Visualizar Boleto"
                              optionalBackground="#fff"
                              optionalTextColor="#5867dd"
                              desktopPadding="16px"
                              mobilePadding="16px"
                            />
                          </Link>
                          <Link
                            to={`https://api.whatsapp.com/send?text=${process.env.REACT_APP_API_PRODUCTION_ADDRESS}/fl-finance/pagarme/financeBillet/${financeBankSlipData.financeId}/billet`}
                            target="_blank"
                          >
                            <ButtonPrimary
                              // absoluteIcon
                              optionalMovingIcon={<RiWhatsappFill />}
                              desktopWidth="100%"
                              disableBorderRadius
                              textButton="Encaminhar Boleto"
                              optionalBackground="#32a84e"
                              desktopPadding="16px"
                              mobilePadding="16px"
                            />
                          </Link>
                        </div>
                        <span className="text-[10px] pb-4 w-full">
                          Ambiente criptografado e 100% seguro.
                        </span>
                        <img
                          alt="Compra Segura"
                          src="/images/assets/CompraSegura.webp"
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default verifyAuth(FinancePage);
