import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ClassContentSection from "../../components/ClassContentSection/ClassContentSection";
import { Toastify } from "../../components/Toastify/Toastify";
import { useLocation, useParams } from "react-router-dom";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import {
  getClassFromDemoCourse,
  getDisciplineAndClasses
} from "../../services/disciplineService/discipline.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import { getStartAvaliationClassPage } from "../../services/avaliationServices/avaliation.service";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";
import { getAllStudentCourses } from "../../services/courseService/course.service";
import { getNextStepsRequest } from "../../services/dashboardService/dashboard.service";

function ClassPageComponent({ demoCourse }) {
  const location = useLocation();
  const [classContent, setClassContent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const classId = useParams().classId;
  const disciplineId = useParams().disciplineId;
  const courseAlias = useParams().courseAlias;
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [avaliationModal, setAvaliationModal] = useState(undefined);
  const [rateCourseBox, setRateCourseBox] = useState(undefined);
  const [studentCourses, setStudentCourses] = useState(undefined);

  const searchParams = new URLSearchParams(location.search);
  const purchaseType = searchParams.get("purchaseType");
  useEffect(() => {
    const fetchClassPageInfo = async () => {
      setIsLoading(true);
      try {
        if (demoCourse) {
          const student = await getStudentInfos();

          const arrayNextSteps = await getNextStepsRequest({
            sessionId: student.sessionId
          });
          if (arrayNextSteps.status !== 200) {
            Toastify("error", "Erro ao buscar informações de steps.");
            console.error(
              "Erro ao buscar informações de steps - 9:",
              arrayNextSteps
            );
          }
          if (!purchaseType) {
            try {
              if (arrayNextSteps.data !== undefined) {
                const nextStep = arrayNextSteps.data.find(
                  (item) => item.status === true
                );
                if (!(nextStep && nextStep.type === "availableCourse"))
                  window.location.href = `/dashboard?message=${encodeURI(
                    "Você não possui cursos disponíveis para resgate."
                  )}`;
              }
            } catch {}
          }

          const arrayStudentCourses = await getAllStudentCourses({
            sessionId: student.sessionId
          });
          let allStudentCoursesArray = [];
          if (arrayStudentCourses.status !== 200)
            window.location.href = `/dashboard?message=${encodeURI(
              "Erro ao buscar informações de curso demonstrativo."
            )}`;
          else {
            if (
              arrayStudentCourses.data.coursesPosGraduation &&
              arrayStudentCourses.data.coursesPosGraduation.length > 0
            ) {
              allStudentCoursesArray = allStudentCoursesArray.concat(
                arrayStudentCourses.data.coursesPosGraduation
              );
            }
            if (
              arrayStudentCourses.data.extraCourses &&
              arrayStudentCourses.data.extraCourses.length > 0
            ) {
              allStudentCoursesArray = allStudentCoursesArray.concat(
                arrayStudentCourses.data.extraCourses
              );
            }
          }

          let studentHasSpecifiedWord = false;
          let specifiedWord = "estudos";

          allStudentCoursesArray.find((course) => {
            if (course.alias.includes(specifiedWord)) {
              studentHasSpecifiedWord = true;
            }
          });

          if (!studentHasSpecifiedWord && courseAlias.includes(specifiedWord)) {
            window.location.href = `/dashboard?message=${encodeURI(
              "Procure o atendimento para a liberação do curso."
            )}`;
          }

          if (
            allStudentCoursesArray.find(
              (course) => course.alias === courseAlias
            )
          )
            window.location.href = `/dashboard?message=${encodeURI(
              "Você já possui acesso a este curso."
            )}`;

          const response = await getClassFromDemoCourse({
            courseAlias: courseAlias
          });
          if (response.status !== 200) {
            Toastify("error", "Erro ao buscar informações de demonstração.");
            console.error(
              "Erro ao buscar informações de demonstração - 1:",
              response.status
            );
          }
          try {
            setClassContent(response.data);
          } catch {
            Toastify("error", "Erro ao processar itens da demonstração: #01");
          }
        } else {
          const student = await getStudentInfos();
          // getDisciplineAndClasses --- START
          const arrayDiscipline = await getDisciplineAndClasses({
            sessionId: student.sessionId,
            classId: classId,
            courseAlias: courseAlias,
            disciplineId: disciplineId
          });
          if (arrayDiscipline.status !== 200) {
            Toastify("error", "Erro ao buscar informações de disciplina.");
            console.error(
              "Erro ao buscar informações de disciplina - 1:",
              arrayDiscipline.status
            );
          }
          // getDisciplineAndClasses --- END
          try {
            setClassContent(arrayDiscipline.data);
            setRateCourseBox(arrayDiscipline.data.currentClass.lastVideoClass);
            const arrayStudentCourses = await getAllStudentCourses({
              sessionId: student.sessionId
            });
            let allStudentCoursesArray = [];
            if (arrayStudentCourses.status == 200) {
              if (
                arrayStudentCourses.data.coursesPosGraduation &&
                arrayStudentCourses.data.coursesPosGraduation.length > 0
              ) {
                allStudentCoursesArray = allStudentCoursesArray.concat(
                  arrayStudentCourses.data.coursesPosGraduation
                );
              }
              if (
                arrayStudentCourses.data.extraCourses &&
                arrayStudentCourses.data.extraCourses.length > 0
              ) {
                allStudentCoursesArray = allStudentCoursesArray.concat(
                  arrayStudentCourses.data.extraCourses
                );
              }
            }
            setStudentCourses(allStudentCoursesArray);
          } catch {
            Toastify("error", "Erro ao processar itens da disciplina: #01");
          }
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar disciplina.");
        window.location.href = "/";
      } finally {
        setIsLoading(false);
      }
    };
    fetchClassPageInfo();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    getStudentInfos()
      .then(async (response) => {
        setStudentInfos(response);
      })
      .catch((error) => {
        Toastify("error", "Erro ao buscar informações do aluno.");
      });
  }, [location, classId]);

  const handleActiveLoadingObj = () => {
    setIsLoading(!isLoading);
  };

  const handleStartAvaliation = async () => {
    try {
      if (!studentInfos)
        return Toastify("error", "Erro ao iniciar avaliação. #1");
      setIsLoading(true);
      const avaliationData = {
        sessionId: studentInfos.sessionId,
        courseId: classContent.courseId,
        disciplineId
      };
      const avaliationResponse = await getStartAvaliationClassPage(
        avaliationData
      );
      if (avaliationResponse.status === 200) {
        const avaliationData = {
          sessionId: studentInfos.sessionId,
          matriculationId: classContent.matriculationId,
          courseId: classContent.courseId,
          courseAlias: courseAlias,
          disciplineId: classContent.disciplineMongoId,
          avaliationType: "avo"
        };
        const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
        window.location.href = `/avaliation?key=${encodeURIComponent(
          String(cryptParam)
        )}`;
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Toastify("error", "Erro ao iniciar avaliação.");
      }
    } catch (err) {
      setIsLoading(false);
      Toastify("error", "Erro ao iniciar avaliação. #2");
    }
  };

  const handleDirectAvaliation = async (item) => {
    if (!studentInfos)
      return Toastify("error", "Erro ao iniciar avaliação direta.");
    const avaliationData = {
      sessionId: studentInfos.sessionId,
      avaliationId: item.avaliationId,
      isViewAvaliation: true
    };
    const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
    window.location.href = `/avaliation?key=${encodeURIComponent(
      String(cryptParam)
    )}`;
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen">
      <HeaderBar
        hiddenHeaderOnLG
        actualPage="Aulas"
        courseNavigation={false}
        mobileBackPage={`/course/${courseAlias}`}
        disableBlurBackground
      />
      <MobileNavigationBar actualPage="Aulas" />
      <LoadingSection isLoading={isLoading} hideScroll />
      <div className="w-full px-14 max-lg:px-0">
        <div className="flex gap-10 w-full h-full pb-9 max-lg:pb-0">
          {classContent && (
            <>
              <ClassContentSection
                studentCourses={studentCourses}
                rateCourseBox={rateCourseBox}
                demoCourse={demoCourse}
                studentInfos={studentInfos}
                classContent={classContent}
                activeLoadingObj={handleActiveLoadingObj}
                handleSetAvaliationModal={setAvaliationModal}
              />
            </>
          )}
        </div>
      </div>
      {avaliationModal && (
        <BlockquoteModal
          text_top="Realizar avaliação"
          text_blockquote={
            avaliationModal.situation !== "Em andamento"
              ? `A prova terá uma duração de ${String(
                  avaliationModal.duration / 60
                ).replace(".", ",")} hora${
                  avaliationModal.duration / 60 >= 2 ? "s" : ""
                }. Além disso, você terá ${String(
                  avaliationModal.retries - avaliationModal.tries
                )} tentativa${
                  avaliationModal.retries >= 2 ? "s" : ""
                } para realizar a avaliação. Está pronto para iniciar?`
              : "Você tem certeza de que deseja dar continuidade a esta avaliação?"
          }
          outsideClick={() => setAvaliationModal(undefined)}
          buttonsLeft={
            (avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
            !avaliationModal.fake
              ? [
                  <ButtonPrimary
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Ver avaliação"
                    desktopWidth="160px"
                    mobileWidth="100%"
                    verifyOnClick={() =>
                      handleDirectAvaliation(avaliationModal)
                    }
                  />
                ]
              : []
          }
          buttonsRight={
            ((avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
              avaliationModal.tries < avaliationModal.retries) ||
            avaliationModal.situation === "Disponível" ||
            avaliationModal.situation === "Em andamento"
              ? [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Cancelar"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />,
                  <ButtonPrimary
                    verifyOnClick={handleStartAvaliation}
                    textButton={`${
                      avaliationModal.situation === "Em andamento"
                        ? "Continuar"
                        : "Começar"
                    }`}
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />
                ]
              : [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    textButton="Voltar"
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />
                ]
          }
        />
      )}
      {/* <section className="w-full px-14 max-lg:px-0"> */}
      {/* Aulas aqui - Secao desabilitada */}
      {/* <div className="flex flex-col w-full gap-6">
          <div className="flex w-full h-full">
            <div className="w-[320px] flex max-md:px-8 gap-6">
              {contentType.map((content, index) => (
                <button
                  key={index}
                  onClick={() => handleActiveContent(content.identifier[0])}
                  className="w-1/3 flex flex-col items-center justify-center rounded-xl">
                  <span className="text-[0.75rem] text-textPrimary font-medium leading-tight overflow-hidden overflow-ellipsis line-clamp-3 select-none pb-3">
                    {content.name}
                  </span>
                  <div
                    className={`${
                      content.identifier.includes(activeContent)
                        ? "w-full"
                        : "w-0"
                    } h-[2px] bg-themeColor transition-all duration-300`}
                  />
                </button>
              ))}
            </div>
          </div>
          <div className="flex w-full h-full">
            <Swiper
              className="w-full"
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-navigation-size": "30px",
                "--swiper-navigation-top-offset": "0%",
                "--swiper-navigation-sides-offset": "0px",
                width: "100%"
              }}
              centeredSlides={true}
              centeredSlidesBounds={true}
              modules={[Navigation]}
              slidesPerView={1.33}
              slidesPerGroup={1}
              navigation={true}
              spaceBetween={18}
              speed={450}
              breakpoints={{
                1025: {
                  centeredSlides: false,
                  centeredSlidesBounds: false,
                  style: { marginLeft: "0%", width: "100%" },
                  slidesPerView: 4,
                  slidesPerGroup: 2
                },
                1400: {
                  centeredSlides: false,
                  centeredSlidesBounds: false,
                  style: { marginLeft: "0%", width: "100%" },
                  slidesPerView: 5,
                  slidesPerGroup: 3
                },
                1900: {
                  centeredSlides: false,
                  centeredSlidesBounds: false,
                  style: { marginLeft: "0%", width: "100%" },
                  slidesPerView: 6,
                  slidesPerGroup: 3
                },
                2400: {
                  centeredSlides: false,
                  centeredSlidesBounds: false,
                  style: { marginLeft: "0%", width: "100%" },
                  slidesPerView: 7,
                  slidesPerGroup: 3
                }
              }}>
              {classContent &&
                classContent.classes &&
                classContent.classes.length > 0 &&
                classContent.classes.map((item, index) => (
                  <SwiperSlide key={`swiper-${index}`} className="w-full">
                    <CourseClassVideoCard
                      position={index}
                      status={!demoCourse || index === 0 ? "unlock" : false}
                      courseAlias={courseAlias}
                      type={item.type}
                      title={item.name}
                      image={item.thumb}
                      classId={item.classId}
                      classprogress={item.progress}
                      description={item.description}
                      classConcluded={item.concluded}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div> */}
      {/* </section> */}
    </main>
  );
}

const WrappedClassPageComponent = verifyAuth(ClassPageComponent);

const ClassPage = (props) =>
  props.demoCourse ? (
    <ClassPageComponent {...props} />
  ) : (
    <WrappedClassPageComponent {...props} />
  );

export default ClassPage;
