import "./CertificatePreview.css";

export default function CertFaculdadeLibanoBack({
  formData,
  selectedCourse,
  certificateContent,
}) {
  const date = new Date();

  const actualNumericDate = String(
    date.toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      timeZone: "America/Sao_Paulo",
    })
  );

  const formatDate = (date) => {
    const dateArray = date.split("T")[0].split("-");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
  };

  if (formData && certificateContent)
    return (
      <div className="relative flex flex-col overflow-hidden pb-4">
        <img
          src="/images/assets/backCertificado-Libano.webp"
          alt="Verso do Certificado"
          className="w-full h-full select-none"
        />
        <div className="absolute top-0 left-0 w-full h-full select-none">
          {/* <div className="flex flex-col pl-[5.8rem] pr-[6rem] py-[7.7rem] gap-y-[1rem]"> */}
          <div className="flex flex-col pt-[3.5%] gap-y-[1rem]">
            <div className="pl-[18.2%] pr-[19.5%] grid grid-cols-2 w-full">
              <div className="flex flex-col max-w-[130px]">
                <p className="text-black text-justify text-[0.31rem]">
                  <b>Nome:</b> {formData.name}
                </p>
                <p className="text-black text-justify text-[0.31rem]">
                  <b>Período:</b>{" "}
                  {formatDate(certificateContent.table.courseDate)} à{" "}
                  {actualNumericDate}
                </p>
                <p className="text-black text-justify text-[0.31rem]">
                  <b>RG:</b> {formData.rg}
                </p>
              </div>
              <div className="flex flex-col max-w-[130px]">
                <p className="text-black text-justify text-[0.31rem] line-clamp-1">
                  <b>Curso:</b> {selectedCourse.label}
                </p>
                <p className="text-black text-justify text-[0.31rem]">
                  <b>Área:</b> {certificateContent.areaName}
                </p>
                <p className="text-black text-justify text-[0.31rem]">
                  <b>Nível:</b> Especialização
                </p>
              </div>
            </div>
            <div className="px-[3.6%] preview-grade-table">
              <table>
                <thead>
                  <tr>
                    <th className="w-[220px] bg-[#f7f8f9]">
                      COMPONENTES CURRICULARES
                    </th>
                    <th className="table-text-center w-[30px] bg-[#f7f8f9]">
                      CH
                    </th>
                    <th className="table-text-center w-[30px] bg-[#f7f8f9]">
                      NOTA
                    </th>
                    <th className="bg-[#f7f8f9]">DOCENTE</th>
                    <th className="pr-1 w-[70px] bg-[#f7f8f9]">TITULAÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  {certificateContent.table.disciplines.map(
                    (discipline, index) => (
                      <tr key={index}>
                        <td className="bg-[#f7f8f9]">{discipline.disciplineName}</td>
                        <td className="table-text-center bg-[#f7f8f9]">
                          {discipline.workload}
                        </td>
                        <td className="table-text-center bg-[#f7f8f9]">
                          {(Number(discipline.grade) || 0).toFixed(1)}
                        </td>
                        <td className="bg-[#f7f8f9]">{discipline.teacherName}</td>
                        <td className="bg-[#f7f8f9]">{discipline.teacherTitration}</td>
                      </tr>
                    )
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" className="table-text-center">
                      <div className="w-full grid grid-cols-2">
                        <p className="text-left">
                          <b>Carga Horária Total:</b>{" "}
                          {certificateContent.table.courseWorkload} horas
                        </p>
                        <p className="text-left">
                          <b>Resultado:</b> Aprovado
                        </p>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                {!certificateContent.table.tcc.dispense && (<tfoot>
                  <tr>
                    <td colSpan="5" className="table-text-center">
                      <div className="w-full grid grid-cols-6">
                        <p className="text-left col-span-3">
                          <b>TCC Tema:</b>{" "}
                          {certificateContent.table.tcc.title}
                        </p>
                        <p className="text-left col-span-2">
                          <b>Tipo:</b> Monografia ou artigo
                        </p>
                        <p className="text-left col-span-1 pl-3">
                          <b>Nota:</b> {certificateContent.table.tcc.grade}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tfoot>)}
              </table>
            </div>
            <div className="bg-white pb-4">
              <div className="grid grid-cols-2 gap-1 w-full px-[3.6%]">
                <div className="flex flex-col gap-1">
                  <div className="p-[7px] border-[1px] border-black rounded-sm h-full">
                    <p className="text-black text-[0.31rem]">
                      Faculdade Líbano CNPJ: 39.511.236/0001-28 Credenciada pela
                      Portaria nª 1881, de 29 de outubro de 2019 Publicada no
                      DOU de 31 de outubro de 2019, Seção 1, Pág. 50
                    </p>
                  </div>
                  <div className="p-[7px] border-[1px] border-black rounded-sm h-full">
                    <p className="text-black text-[0.31rem]">
                      Na avaliação da aprendizagem, é exigida a obtenção de nota
                      mínima igual ou superior a 6, mediante a prática de
                      utilização de trabalhos e provas.
                    </p>
                  </div>
                </div>
                <div className="p-[7px] border-[1px] border-black rounded-sm h-full">
                  <div className="flex flex-col gap-3">
                    <p className="text-black text-[0.31rem]">
                      Certificado registrado de acordo com o disposto no § 2º do
                      art. 8º da Resolução nº 1, de 06 de abril de 2018, da
                      Câmara de Educação Superior do Conselho Nacional de
                      Educação.
                    </p>
                    <div className="flex justify-end w-full">
                      <p className="text-black text-[0.31rem] w-[5rem]">
                        Responsável pelo Registro Marcela Barros Alves Ferreira Secretaria Acadêmica
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
